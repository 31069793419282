import { Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { colors } from "../../common/colors";
interface Props {
  onClick(): void;
  title: string;
  desc: string;
}
export default function ButtonArrow2({ title, desc, onClick }: Props) {
  return (
    <Box
      style={{
        height: 40,
        marginLeft: 16,
        marginRight: 16,
        borderRadius: 8,
        marginBottom: 12,
        padding: "30px 10px 30px 10px",
        alignItems: "center",
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: colors.farm_gray100,
        borderStyle: "solid",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        style={{
          flexDirection: "column",
          display: "flex",
        }}
        onClick={onClick}
      >
        <Typography style={{ fontSize: 13 }} color={"black"}>
          {title}
        </Typography>
        <Typography style={{ fontSize: 12 }} color={"#A2A2A2"}>
          {desc}
        </Typography>
      </Box>
      <ArrowForwardIosIcon style={{ marginRight: 6, fontSize: 14 }} />
    </Box>
  );
}
