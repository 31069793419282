import React, { useEffect, useState } from "react";
import { Avatar, Box, ListItem, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import UserCardList from "../../../components/Models/GreenCity/UserCardList";
import { useSelector } from "react-redux";
// import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import { Createchat, GroupMember } from "../../../util/types";
import appServices from "../../../services/app-services/appServices";
import MessageModal from "../../../components/Models/GreenCity/MessageModal";
import ChatIcon from "@mui/icons-material/Chat";
import LoadingModal from "../../../components/LoadingModal";

const ChatList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { groupInfo } = location?.state || {};
  const { user } = useSelector((state: any) => state.userAuth);
  const [groupChatRes, setGroupChatRes] = useState<any>({});
  const [members, setMembers] = useState<GroupMember[]>([]);
  const [member, setMember] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [leaders, setLeaders] = useState<GroupMember[]>([]);
  const [totalMembers, setTotalMembers] = useState();
  useEffect(() => {
    apiCall();
    // eslint-disable-next-line
  }, []);

  const apiCall = async () => {
    setLoading(true);
    const res = await gcServices.getMemberList(groupInfo?.id);
    const chatRes = await appServices.showGroupChat(groupInfo?.id);
    setGroupChatRes(chatRes);
    setTotalMembers(res.member_group_users.length);
    setLoading(false);
    let mem = [];
    let lead = [];
    for (let i = 0; i < res.member_group_users.length; i++) {
      if (res.member_group_users[i].role === "member") {
        mem.push(res.member_group_users[i]);
      } else {
        lead.push(res.member_group_users[i]);
      }
    }
    setMembers(mem);
    setLeaders(lead);
  };

  const handleDelete = async (id: any) => {
    setLoading(true);
    try {
      await gcServices.deleteMember(member?.id);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleMoreOptions = (id: any) => {
    console.log(`More options for user with ID ${id}`);
  };

  const handleGroupChat = async () => {
    try {
      const res = groupChatRes;

      // Check if a group chat already exists
      const existingGroupChat = res?.chats?.find((chat: any) => chat.chat_type === "group" && chat.member_group_id === groupInfo?.id);
      // console.log("chat users: ", existingGroupChat);
      if (existingGroupChat) {
        // Navigate to existing group chat
        navigate("/userchat", {
          state: {
            chatId: existingGroupChat.id,
            chatUsers: existingGroupChat.users,
            chat_type: "group",
            groupMembers: [...leaders, ...members],
            groupInfo: groupInfo,
            isCreator: groupInfo?.owner?.id === user.id,
            isGroupChat: true,
          },
        });
      } else {
        // Create new group chat
        const chatPayload: Createchat = {
          chat_type: "group",
          member_group_id: groupInfo?.id,
          users: [...leaders, ...members].map((member) => ({
            user_id: member.user.id,
            can_message: true,
          })),
        };

        const response = await appServices.createChat(chatPayload);
        navigate("/userchat", {
          state: {
            chatId: response.data.chat.id,
            chatUsers: [...leaders, ...members].map((member: any) => ({
              id: member.user.id,
              name: member.user.name,
              nickname: member.user.nickname,
              image: getMemberImage(member.user.id),
              can_message: true,
            })),
            chat_type: "group",
            groupMembers: [...leaders, ...members],
            groupInfo: groupInfo,
            isCreator: groupInfo?.owner?.id === user.id,
            isGroupChat: true,
          },
        });
      }
    } catch (error) {
      console.error("Failed to handle group chat:", error);
      setErrorMessage("グループチャットの作成に失敗しました");
    }
  };

  const handleAddNewUser = (newUser: any) => {
    appServices
      .addUserToChat(groupChatRes?.chats[0]?.id, newUser.id, user.auth_token)
      .then((res: any) => {
        console.log("user added: ", res);
        setOpenAddUserModal(true);
        //show model for success message of a new user added
        setGroupChatRes({
          ...groupChatRes,
          chats: [
            {
              ...groupChatRes?.chats[0],
              users: [
                ...groupChatRes?.chats[0]?.users,
                {
                  id: newUser.id,
                  name: newUser.name,
                  can_message: true,
                  creator: false,
                },
              ],
            },
          ],
        });
      })
      .catch((error) => {
        console.log("user error on add: ", error);

        //show model for error
      });
  };

  const getGroupOwnerObject = () => {
    const obj = {
      role: "Owner",
      user: {
        id: groupInfo?.owner?.id,
        name: groupInfo?.owner?.name,
        image: groupInfo?.owner?.image,
      },
    };
    return obj;
  };

  const getMemberImage = (memberId: number) => {
    const memberInfo = groupInfo?.member_group_users?.find((groupMember: any) => groupMember.id === memberId);
    if (!memberInfo?.image) return "";
    return `data:image/jpeg;base64,${memberInfo.image}`;
  };

  const checkIfUserInChat = (user_id: string) => {
    let find = groupChatRes?.chats[0]?.users.find((user: any) => user.id === user_id);
    if (find) return true;
    return false;
  };

  const singleUserChat = async (newUser_id: any) => {
    console.log("create single user chat", newUser_id);
    try {
      const existChat = await appServices.singleUserChatExist(groupInfo?.id, newUser_id, user.auth_token);

      // Find chat where both users exist
      const existingChat = existChat?.chats?.find(
        (chat: any) => chat.chat_type === "one_to_one" && chat.users.some((u: any) => u.id === newUser_id) && chat.users.some((u: any) => u.id === user.id)
      );
      console.log("existingChat: ", existingChat);
      if (existingChat) {
        navigate("/userchat", {
          state: {
            chatId: existingChat.id,
            chatUsers: existingChat.users,
            chat_type: "one_to_one",
            isGroupChat: false,
            isCreator: existingChat.creator_id === user.id,
            groupMembers: [getGroupOwnerObject(), ...leaders, ...members],
            groupInfo: groupInfo,
          },
        });
      } else {
        const response = await appServices.addSingleUserToChat(groupInfo?.id, newUser_id, user.auth_token);
        console.log("New chat response:", response);

        if (response?.chat?.id) {
          navigate("/userchat", {
            state: {
              chatId: response.chat.id,
              chatUsers: response.chat.users,
              chat_type: "one_to_one",
              isGroupChat: false,
              isCreator: response.chat.creator_id === user.id,
              groupMembers: [getGroupOwnerObject(), ...leaders, ...members],
              groupInfo: groupInfo,
            },
          });
        }
      }
    } catch (error) {
      console.error("Failed to create single user chat:", error);
      setErrorMessage("チャットの作成に失敗しました");
    }
  };

  const handleRoles = (user: any) => {
    console.log(user);

    navigate("/grouproles", { state: { groupInfo: groupInfo, member: user } });
  };

  return (
    <Box>
      <Box style={style.headerContainer}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "10px",
          }}
        >
          <Box style={style.eventPostingBox}>
            <Box component="div" style={style.eventPostingIcon}>
              <Avatar src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`} sx={{ width: 30, height: 30 }} />
            </Box>
            <Typography style={{ fontWeight: "700", marginTop: "5px" }}>グループメンバー</Typography>
          </Box>
          {groupInfo?.owner?.id === user.id && <ChatIcon onClick={handleGroupChat} />}
        </Box>
      </Box>
      <Box style={style.totalCount}>グループメンバー全員 {totalMembers ? totalMembers + 1 : 0}人</Box>

      <Typography sx={{ textAlign: "left", marginLeft: "15px" }}>Group Admins</Typography>

      <Box sx={{ marginBottom: "20px" }}>
        <ListItem disableGutters>
          <UserCardList
            currentUserId={user?.id}
            key={groupInfo.owner?.id}
            onChatPress={() => singleUserChat(groupInfo?.owner?.id)}
            user={groupInfo?.owner}
            role={groupInfo?.owner?.role || "Admin"}
            userName={groupInfo?.owner?.name}
            onApprove={() => setMember(groupInfo?.owner)}
            onMoreOptions={() => handleMoreOptions(groupInfo?.owner.id)}
            onSwitchingRoles={(user) => {
              handleRoles(user);
            }}
            userAvatar={`data:image/jpeg;base64,${groupInfo?.owner.image}`}
          />
        </ListItem>
        {leaders.map((leader) => (
          <ListItem disableGutters>
            <UserCardList
              currentUserId={user?.id}
              key={leader?.id}
              onChatPress={() => singleUserChat(leader?.id)}
              userName={leader?.user?.name}
              role={leader?.role || ""}
              onApprove={() => setMember(leader?.user)}
              onMoreOptions={() => handleMoreOptions(leader?.user?.id)}
              onSwitchingRoles={(user) => {
                handleRoles(user);
              }}
              userAvatar={`data:image/jpeg;base64,${groupInfo?.owner.image}`}
            />
          </ListItem>
        ))}
      </Box>

      <Typography sx={{ textAlign: "left", marginLeft: "15px" }}>役割を持っているメンバー</Typography>

      {!loading &&
        members?.map((member: any) => {
          return (
            <Box>
              <ListItem disableGutters>
                <UserCardList
                  currentUserId={user?.id}
                  key={member?.user?.id}
                  onChatPress={() => singleUserChat(member?.user?.id)}
                  user={member?.user}
                  userName={member?.user?.nickname || member?.user?.name}
                  role={member?.role || "member"}
                  showApproveIcon={member?.status === "Pending"}
                  onApprove={() => setMember(member)}
                  onDelete={() => handleDelete(member.id)}
                  onMoreOptions={() => handleMoreOptions(member.id)}
                  userAvatar={getMemberImage(member?.user?.id)}
                  groupOwnerId={groupInfo.owner?.id}
                  isInChat={checkIfUserInChat(member?.user?.id)}
                  onAddNewUser={handleAddNewUser}
                  onSwitchingRoles={(user) => {
                    handleRoles(user);
                  }}
                  openAddUserModal={openAddUserModal}
                  setOpenAddUserModal={setOpenAddUserModal}
                />
              </ListItem>
            </Box>
          );
        })}
      <MessageModal onContinue={() => console.log("clicked")} open={errorMessage.length > 0} message={errorMessage} onClose={() => setErrorMessage("")} />
      <LoadingModal visible={loading} handleClose={() => console.log("")} />
    </Box>
  );
};
const style = {
  eventPostingIcon: {
    // background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px 3px 6px",
    color: "white",
  },
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItmes: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  totalCount: {
    backgroundColor: "#F4F4F4",
    marginTop: 20,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 20,
    borderRadius: 10,
    padding: "15px 10px 15px 10px",
  },
  headerContainer: {
    paddingTop: 100,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: "12px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
};
export default ChatList;
