import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import EventCalendar from "../../components/GreenCity/EventCalender";
import { useSelector } from "react-redux";
import EventDetailsList from "../../components/lists/EventDetailsList";
import {
  removeOpinionPost,
} from "../../redux/Reducers/postReducer";
import appServices from "../../services/app-services/appServices";
import profile from "../../assets/images/profile_img.png";

const EventDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const event = location.state?.event;
  const [selectedDate, setSelectedDate] = useState(null);
  console.log(event, "event");

  const [secondStep, setSecondStep] = useState(false);
  const { opinionPosts, opinionPostsPagination } =
    useSelector((state: any) => state.posts);

  const getSelectedEvent = (event: any) => {
    navigate("/eventdetail", { state: { event: event } });
    setSelectedDate(event.start);
  };

  const handleReport = async (post_id: number, status: any) => {
    try {
      // setLoading(true);
      const response = await appServices.acceptRejectPost(post_id, status);
      console.log(response, "responseeeeeee");
      // setLoading(false);
      setTimeout(() => {
        dispatch(removeOpinionPost(post_id));
      }, 1000);
    } catch (error) {
      // setLoading(false);
    }
  };
  const [likeLoading, setLikeLoading] = useState(false);
  const like = async (item: any, action: string) => {
    setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, action);

      try {
        // let data = { page: opinionPostsPagination.current_page, order: order };
        // await dispatch(getOpinionPosts(data));

        setLikeLoading(false);
      } catch (error) {}
    } catch (error) {
      setLikeLoading(false);
    } finally {
    }

    // console.log('like')
  };
  const handleThumbUp = async (item: any) => {
    setLikeLoading(true);

    try {
      if (item?.liked_by_me) {
        await like(item, "delete_like");
        // setLikeCount(likeCount - 1); // Decrease the like count
      } else {
        await like(item, "like");
        // setLikeCount(likeCount + 1); // Increase the like count
      }
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      setLikeLoading(false);
    }
    // console.log(likeCount,'likeCounttttttttttttt')
  };
  useEffect(() => {
    setSelectedDate(event.start);
    // eslint-disable-next-line
  }, []);
  return (
    <Box>
      <Box
        style={{
          paddingTop: 100,
          paddingLeft: 16,
          paddingBottom: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />

        <Box style={styles.eventPostingBox}>
          <Avatar style={{ margin: 0 }} alt="Remy Sharp" src={profile} />
          <Typography style={{ fontWeight: "700", marginTop: "5px" }}>
            Event Details
          </Typography>
        </Box>
      </Box>
      {!secondStep && (
        <>
          <Box>
            <EventCalendar
              getSelectedEvent={getSelectedEvent}
              selectedDate={selectedDate}
            />
          </Box>
          <EventDetailsList
            handleReport={handleReport}
            page={opinionPostsPagination?.current_page}
            order={""}
            items={[opinionPosts[0]]}
            onLike={handleThumbUp}
            onUnLike={handleThumbUp}
            likeLoading={likeLoading}
            Next={setSecondStep}
          />
        </>
      )}
      {secondStep && (
        <Box>
          <Typography>ssadasese</Typography>
        </Box>
      )}
    </Box>
  );
};
const styles = {
  eventPostingIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px 3px 6px",
    color: "white",
  },
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItmes: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
};
export default EventDetails;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
