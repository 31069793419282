import { Box, Button, Checkbox, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import GCBackButton from '../../../components/GreenCity/GCBackButton'
import { styles } from '../../styles';
import profile from "../../../assets/images/profile_img.png";
import Separator from '../../../components/Separator';
import { colors } from '../../../common/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../../../components/Models/GreenCity/ConfirmationModal';

// interface Props {
  
// }

function GroupPayment(){
    const location = useLocation();
    const { groupInfo } = location.state || {}; // Destructure your props
    const navigate=useNavigate();
    const { user } = useSelector((state: any) => state.userAuth);
    const [agree1,setAgree1]=useState(false);
    const [agree2,setAgree2]=useState(false);
    const [agree3,setAgree3]=useState(false);

    const [openModal,setModal]=useState(false);


    const [selectedGroup,setGroup]=useState<any>();
    useEffect(() => {
      window.scrollTo(0, 0);
    
      // eslint-disable-next-line
    }, []);

    useEffect(()=>{

      if(groupInfo?.id){
        setGroup(groupInfo);
        console.log('===',groupInfo)
      }

      setModal(true);

    },[groupInfo])
    
    const handleSubmit=()=>{};
  return (
    <div style={style.container}>
       <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
       <ConfirmationModal open={openModal} message={
       <><Typography style={{textAlign:'center'}}>本グループの参加には入会金が必要なため、めぶくPayでの入会金の支払い手続きをお願いいたします。</Typography>
       <Typography style={{textAlign:'center'}}> 支払い完了後、グループコンテンツの閲覧が可能になります。'</Typography> </>} showButton onContinue={()=>{ setModal(false)}} onClose={()=>{ setModal(false)}} />
      <Box style={{ padding: 16 }}>
        <Typography style={{ fontWeight: "700" }}>
          <img
           src={
            user.image !== null
              ? "data:image/jpeg;base64," + user.image
              : profile
          }
            alt={"user_img"}
            style={{
              ...styles.farmProfileIcon,
              height: 50,
              width: 50,
              margin: 0,
            }}
          />
          &nbsp;{user?.name||user?.nickname}
        </Typography> 
      </Box>
      <div style={{backgroundColor:colors.farm_gray15}}>
      <Separator sx={{ marginTop: "10px",marginBottom: "10px" }} color="gray" />
      <Box  style={{ padding: 16 }}>
      <Typography>お支払い先</Typography>
      <Separator sx={{ marginTop: "2px" }} color={colors.farm_green200} />

      <Box style={style.marginTB}>
        <Typography style={{ fontWeight: "700" }}>
          <img
            src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${selectedGroup?.group_icon}`}
            alt={"img"}
            style={{
              ...styles.farmProfileIcon,
              height: 40,
              width: 40,
              margin: 0,
            }}
          />
          &nbsp;{selectedGroup?.name}
        </Typography>
       
      </Box>
      {/* <ButtonPrimary title='メンバーを選ぶ' onClick={()=>{}} /> */}
      <br/>
      <Typography>種別</Typography>
      <Separator sx={{ marginTop: "2px" }} color={colors.farm_green200} />
      <RadioGroup
          aria-labelledby="payment"
          defaultValue="membership_fee"
          name="fee"
        >
    <FormControlLabel value="event_fee" control={<Radio />} label="イベント費" />
    <FormControlLabel value="membership_fee" control={<Radio />} label="会費" />
    <FormControlLabel value="other_fee" control={<Radio />} label="その他" />  
  </RadioGroup>
    
      

      <Typography>金額</Typography>
      <Separator sx={{ marginTop: "2px" }} color={colors.farm_green200} />
      <br/>
      <div style={{display:'flex', justifyContent:'center',alignItems:'baseline'}}>  
      <TextField value={groupInfo?.joining_fee_amount}   sx={{ input: { textAlign: 'right' } }} id="outlined-basic"  hiddenLabel variant="outlined" />円</div>
      <Typography style={style.balance}>グループの「めぶくPay」残高：85,681円</Typography>
      <br/>
      <Typography>メモ</Typography>
      <Separator sx={{ marginTop: "2px" }} color={colors.farm_green200} />
      <br></br>
      <TextField  multiline rows={2} hiddenLabel fullWidth variant="outlined" />
      <br/>
<br/>
      <div style={{ padding: "15px 10px" }}>
      <Typography>注意事項</Typography>
      <Separator sx={{ marginTop: "2px" }} color={colors.farm_green200} />
        <FormControlLabel
          control={<Checkbox checked={agree1} onChange={(e) => setAgree1(e.target.checked)} />}
          color="error"
          sx={{ color: false ? colors.farm_red600 : "black", marginTop: "30px" }}
          label={'お支払い後、即時めぶくPayより指定の金額が支払われます。'}
        />
          <FormControlLabel
          control={<Checkbox checked={agree2} onChange={(e) => setAgree2(e.target.checked)} />}
          color="error"
          sx={{ color: false ? colors.farm_red600 : "black", marginTop: "20px" }}
          label={'お支払い後の払い戻しはできません。'}
        />
          <FormControlLabel
          control={<Checkbox checked={agree3} onChange={(e) => setAgree3(e.target.checked)} />}
          color="error"
          sx={{ color: false ? colors.farm_red600 : "black", marginTop: "20px" }}
          label={'注意事項を確認しました。'}
        />
      </div>

      {/* Submit Button */}
      <Button
        disabled={!agree1 || !agree2 || !agree3}
        color="secondary"
        sx={style.submitButton}
        size={"large"}
        variant="outlined"
        onClick={handleSubmit}
        endIcon={<FontAwesomeIcon style={{ position: "absolute", top: 0, right: 0, margin: "10px" }} icon={faChevronRight} />}
      >
        投稿する
      </Button>
      </Box>
      </div>
    </div>
  )
}

export default GroupPayment


const style = {
  container: {
    paddingTop: 90,
  },
  marginTB:{
    marginTop: "10px",
    marginBottom: "10px"
  },
  balance:{
    fontSize:12,
    color:colors.farm_gray200
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
    marginBottom:8
  },
  
};