import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, TextField, IconButton, CircularProgress, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import moment from "moment";
import { Message } from "../../util/types";
import { useNavigate } from "react-router-dom";
// Define the Message interface
interface ChatProps {
  messages: Message[];
  handleSendMessage: (message: string) => void;
  currentUser: any;
  isLoading?: boolean;
  pendingMessage?: string | null;
}

const Chat: React.FC<ChatProps> = ({ messages, handleSendMessage, currentUser, isLoading, pendingMessage }) => {
  const [newMessage, setNewMessage] = useState<string>("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();

  // Scroll to bottom of the chat container when new messages are added
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Detect links in the message
  const detectLink = (text: string) => {
    // Regular expression to detect HTTP(S) and WWW links
    const urlPattern = /(\bhttps?:\/\/|www\.|[^\s]+\.com)\b/gi;
    return urlPattern.test(text);
  };
   const copyToClipboard = (text:any) => {
    navigator.clipboard.writeText(text)
    .then(() => alert('Copied successfully!'))
    .catch(err => alert('Failed to copy: ' + err));
};

  // Handle scroll to bottom on new message or pending message change
  useEffect(() => {
    scrollToBottom();
  }, [messages, pendingMessage]);

  // Format the date difference for displaying message dates
  const getMessageDateDifference = (index: number) => {
    const currentMessage = messages[index];
    const previousMessage = messages[index - 1];

    if (!previousMessage) {
      return moment(currentMessage?.created_at).format("YYYY-MM-DD");
    }

    const previousMessageDate = moment(previousMessage.created_at).format("YYYY-MM-DD");
    const currentMessageDate = moment(currentMessage?.created_at).format("YYYY-MM-DD");

    return previousMessageDate === currentMessageDate ? "" : currentMessageDate;
  };

  return (
    <Box>
      <Box sx={styles.chatContainer}>
        {messages?.map((msg, index) => (
          <Box key={index}>
            {getMessageDateDifference(index) !== "" && (
              <Box id="date" sx={styles.chatDate}>
                <Typography sx={styles.chatDateText}>{getMessageDateDifference(index)}</Typography>
              </Box>
            )}
            <Box
              className={`msg ${msg.user_id}`}
              sx={{
                ...styles.messageContainer,
                flexDirection: msg.user_id === currentUser.id ? "row-reverse" : "row",
              }}
            >
              <Box
                sx={{
                  ...styles.messageBubble,
                  backgroundColor: msg.user_id === currentUser.id ? "#B7E68E" : "white",
                  borderRadius: msg.user_id === currentUser.id ? "20px 20px 0 20px" : "20px 20px 20px 0",
                }}
              >
                {msg.user_id === currentUser.id ? (
                  <Typography sx={{ fontSize: "14px", color: "#333", fontWeight: "bold", letterSpacing: "0.05em" }}></Typography>
                ) : (
                  messages[index - 1]?.name !== msg.name && (
                    <Typography sx={{ fontSize: "14px", color: "#333", fontWeight: "bold", letterSpacing: "0.05em" }}>{msg.name}</Typography>
                  )
                )}
                <Typography sx={{wordBreak:"break-all"}}>
                  {msg.message.split(" ").map((word, index) => {
                    if (detectLink(word)) {
                      return (
                        <span key={index} style={{ display: "inline-flex", alignItems: "center" }}>
                          <a
                            href={word}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#1E90FF", textDecoration: "none" }}
                            onClick={(e) => {
                              if (word.startsWith('https://mebuku.innov8.jp/groupinvitation?link=')) {
                                e.preventDefault(); // Prevent default link behavior
                                navigate('/groupinvitation', { state: { link: word } });
                              }
                            }}
                          >
                            {word}
                          </a>
                            <Tooltip title="Copy link">
                              <IconButton onClick={() => {
                                  copyToClipboard(word);
                              }} size="small" sx={{ marginLeft: "5px" }}>
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                        </span>
                      );
                    } else {
                      return word + " ";
                    }
                  })}
                </Typography>
              </Box>
              {msg.created_at && (
                <Box sx={styles.messageTime}>
                  <Typography sx={styles.messageTimeText}>{moment(msg.created_at).format("HH:mm")}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        ))}
        {pendingMessage && (
          <Box sx={{ ...styles.messageContainer, flexDirection: "row-reverse" }}>
            <Box sx={{ ...styles.messageBubble, backgroundColor: "#B7E68E", borderRadius: "20px 20px 0 20px" }}>
              <Typography>{pendingMessage}</Typography>
            </Box>
            <Box sx={styles.messageTime}>
              <CircularProgress size={15} />
            </Box>
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>
      <Box sx={styles.inputContainer}>
        <IconButton sx={styles.addButton}>
          <AddIcon />
        </IconButton>
        <TextField fullWidth variant="outlined" placeholder="" size="small" value={newMessage} onChange={(e) => setNewMessage(e.target.value)} sx={styles.inputField} />
        <IconButton
          sx={[styles.sendButton, { backgroundColor: newMessage.trim() === "" ? "gray" : "#B7E68E" }]}
          onClick={() => {
            if (newMessage.trim() === "") return;
            handleSendMessage(newMessage);
            setNewMessage("");
          }}
        >
          {isLoading ? <CircularProgress size={20} /> : <SendIcon sx={[styles.sendIcon]} />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default Chat;

const styles = {
  chatContainer: {
    backgroundColor: "#ECEFF1",
    height: "calc(100vh - 230px)",
    maxHeight: "90vh",
    width: "100%",
    padding: "20px 15px",
    overflowY: "auto",
  },
  chatDate: {
    margin: "15px auto",
    background: "#D1D1D1",
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
    padding: "5px 10px",
    borderRadius: "30px",
  },
  chatDateText: {
    fontSize: "12px",
  },
  messageContainer: {
    display: "flex",
    alignItems: "flex-end",
    gap: "10px",
    marginTop: "15px",
  },
  messageBubble: {
    padding: "10px 16px",
    width: "fit-content",
    boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
    maxWidth: "70%",
  },
  messageTime: {
    textAlign: "center",
  },
  messageTimeText: {
    fontSize: "12px",
  },
  readReceipt: {
    fontSize: "12px",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    backgroundColor: "#F4F4F4",
    padding: "20px 16px",
    borderTop: "2px solid #F4F4F4",
  },
  addButton: {
    background: "#B9B9B9",
    "&:hover": { background: "#B7E68E" },
    color: "#fff",
    display: "none",
  },
  inputField: {
    backgroundColor: "white",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "& fieldset": {
        borderColor: "#B9B9B9",
      },
      "&:hover fieldset": {
        borderColor: "#B9B9B9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#B9B9B9",
      },
    },
  },
  sendButton: {
    color: "#fff",
    background: "#B9B9B9",
  },
  sendIcon: {
    transform: "rotate(-45deg)",
  },
};
