import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { useNavigate, useLocation } from "react-router-dom";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import TableComponent from "../../components/GreenCity/TableComponent";
import GroupIcon from "@mui/icons-material/Group";
import profile from "../../assets/images/profile_img.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import OptionsModal2 from "../../components/Models/OptionsModal2";
import LoadingModal from "../../components/LoadingModal";

interface EventDetail {
  address: string;
  anyone_can_participate: boolean;
  application_cancellation_rule: string;
  application_end_date: string;
  application_install_needed: boolean;
  application_start_date: string;
  description: string;
  end_date: string;
  id: number;
  participant_settings: {
    id: number;
    name: string;
    number_of_participants: number | null;
    fee_amount: string | null;
    free: boolean;
  }[];
  post_id: number;
  postal_code: string;
  start_date: string;
  title: string;
  url: string;
  users: {
    id: number;
    name: string;
    status: string;
    role: null;
    application_details: null;
  }[];
  venue_name: string;
  voting_question: string;
}
interface ApplicationDetails {
  id: number;
  name: string;
  free: boolean;
  group: boolean;
  fee_amount: string;
  number_of_participants: number;
  total_amount: number;
}

interface User {
  id: number;
  name: string;
  status: string;
  role: string | null;
  application_details: string; // JSON string
}

const EventUsers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { event } = location.state || {};
  const [eventDetail, setEventDetail] = useState<EventDetail>({
    address: "",
    anyone_can_participate: false,
    application_cancellation_rule: "",
    application_end_date: "",
    application_install_needed: false,
    application_start_date: "",
    description: "",
    end_date: "",
    id: 0,
    participant_settings: [],
    post_id: 0,
    postal_code: "",
    start_date: "",
    title: "",
    url: "",
    users: [],
    venue_name: "",
    voting_question: "",
  });
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [users, setUsers] = useState([]);

  const [openOptions, setOpenOptions] = useState<boolean>(false);
  const Options = [
    { title: "ユーザーのページにアクセスする", path: "" },
    { title: "プライベート投稿を送信する", path: "/chat" },
    { title: "役割を変える", path: "" },
    { title: "強制撤退", path: "" },
  ];

  console.log(event, "eventId");
  const getEventDetails = async () => {
    try {
      setLoading(true);
      const res = await gcServices.getSingleEvent(event?.eventId);
      console.log(res?.post_event, "res");

      setEventDetail(res?.post_event);
      setUsers(res?.post_event?.users);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const filterUsersByCategory = (category: string) => {
    const filtered = users.filter((user) => {
      const appDetails: ApplicationDetails[] = JSON.parse(
        (user as User).application_details
      );
      return appDetails.some(
        (detail) =>
          detail.name === category && detail.number_of_participants > 0
      );
    });

    setFilteredUsers(filtered);
  };

  const handleClick = (index: number) => {
    setSelectedIndex(index);
  };

  const changeUserStatus = async (user: any) => {
    let body = {
      id: user?.id,
      status: "confirmed",
    };
    try {
      const res = await gcServices.updateUserStatus(body);
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEventDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <LoadingModal visible={loading} handleClose={() => {}} />

      <Box style={style.headBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={{ display: "flex", gap: 1, marginTop: 1 }}>
          <Box sx={style.BoxIcon}>中</Box>
          <Typography sx={{ fontWeight: "bold" }}>
            参加者確定のイベントメンバー
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: "0 20px" }}>
        <Box sx={{ marginTop: 4 }}>
          <TableComponent
            data={[
              {
                col1: "イベント名",
                col2: event?.eventTitle,
              },
              {
                col1: "参加人数",
                col2: event?.eventStartDate,
              },
            ]}
          />
        </Box>
        <Box sx={style.ApplicationFilterBox}>
          <Typography sx={{ fontWeight: "bold" }}>参加項目</Typography>
          <Box sx={{ marginTop: 2 }}>
            {eventDetail?.participant_settings?.map(
              (item: any, index: number) => (
                <Box
                  sx={{
                    ...style.ApplicationFilter,
                    backgroundColor:
                      selectedIndex === index ? "#90C660" : "white",
                  }}
                  key={index}
                  onClick={() => {
                    filterUsersByCategory(item?.name);
                    handleClick(index);
                  }}
                >
                  <Typography sx={{}}>
                    {item?.name}：
                    {item?.group
                      ? "1名様："
                      : `${item?.number_of_participants}名様：`}
                    {item?.free ? "無料" : `${item?.fee_amount}円`}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>
        <Box
          sx={{
            padding: "20px 0px",
            marginTop: 1,
          }}
        >
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <GroupIcon /> メンバー一覧
          </p>
          {filteredUsers?.map((user: any, index: number) => {
            return (
              <Box>
                <Box sx={style.UserBox}>
                  <Typography>{index + 1}</Typography>
                  <Box sx={style.UserDetail}>
                    <Avatar
                      alt="Remy Sharp"
                      sx={{ width: 30, height: 30 }}
                      src={profile}
                    />
                    <Typography>{user?.name}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginLeft: "auto" }}>
                    <CheckCircleIcon
                      sx={{
                        color: user?.status === "confirmed" ? "green" : "gray",
                        fontSize: "33px",
                      }}
                      onClick={() => changeUserStatus(user)}
                    />
                    <Box
                      sx={{ margin: "0 5px" }}
                      style={style.optionButton}
                      onClick={() => setOpenOptions(true)}
                    >
                      <MoreHorizIcon sx={style.MoreOptions} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
          <OptionsModal2
            open={openOptions}
            onClose={() => setOpenOptions(false)}
            options={Options}
            onSelect={() => {}}
          />
        </Box>
      </Box>
    </Box>
  );
};
const style = {
  optionButton: {
    border: "1px solid #4792E0",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1px",
  },
  headBox: {
    paddingTop: 100,
    paddingLeft: 16,
    paddingBottom: "12px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
  BoxIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    padding: 1.5,
  },

  ApplicationFilterBox: {
    padding: "30px 20px",
    backgroundColor: "#F4F4F4",
    marginTop: 4,
    borderRadius: 2,
  },
  ApplicationFilter: {
    marginTop: 2,
    display: "flex",
    gap: 1,
    padding: "12px 10px",
    borderRadius: "5px",
  },
  UserBox: {
    borderBottom: "1px gray solid",
    marginTop: "10px",
    textAlign: "left",
    paddingBottom: "8px",
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
  },
  UserDetail: {
    display: "flex",
    alignItems: "center",
    marginLeft: 2,
    gap: 1,
  },
  MoreOptions: {
    color: "#4792E0",
    cursor: "pointer",
    fontSize: "25px",
  },
};
export default EventUsers;
