import { Box, CircularProgress, InputAdornment, TextField } from "@mui/material";
import React, { ReactElement, useState, useCallback, useMemo, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import GCBackButton from "../../../../components/GreenCity/GCBackButton";
import LinkIcon from '@mui/icons-material/Link';
import IconButtonArrow from "../../../../components/GreenCity/IconButtonArrow";
import { setGroupInfo } from "../../../../redux/Reducers/gcGroupReducer";
import axios, { CancelTokenSource } from "axios";
import gcServices from "../../../../services/app-services/greenCity/gcServicesR6";

function GroupInvite(): ReactElement {
  const [groups, setGroups] = useState<any>();
  const location = useLocation(); 
  const { link } = location.state || {};
  // const [reSearch, setReSearch] = useState(false);
  const navigate = useNavigate();
  const dispatch =useDispatch();
  const { user } = useSelector((state: any) => state.userAuth);

  // Memoize groups to avoid unnecessary re-renders if groups don't change
  const memoizedGroups = useMemo(() => groups, [groups]);

  // Memoize handleSearch to avoid re-creating the function on every render
 
  const handleGroup = (group: any) => {
    if (group?.owner?.id === user?.id) {
      navigate("/groupleader", {
        state: {
          groupInfo: {...group,user:group?.owner},
        },
      });
    } else if (group?.user?.id !== user?.id) {
      handleReview(group);
    }
  };

  const handleReview = (groupInfo: any) => {
    // Dispatch action to save group details
    dispatch(
      setGroupInfo({
        user:groupInfo?.owner,
        id: groupInfo.id,
        groupName: groupInfo.name,
        groupIsPublic: groupInfo?.group_type,
        groupImage: groupInfo?.group_icon?`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`:'',
        groupMainImage:groupInfo?.banner_image? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.banner_image}`:'', // From state
        groupDesc: groupInfo?.description, // From state
        groupAnnouncements: groupInfo?.member_group_activity_values_attributes, // From state
        groupWebURLs: groupInfo?.social_links, // You can replace this with the actual value
        groupApprovalRequired: groupInfo?.approval_required,
        piiRequired: groupInfo?.personal_information_needed ? "YES" : "NO",
        groupIsLimited: true,
        groupMaxMembers: groupInfo.participants_count,
        groupMemberShipFeeAmount: groupInfo?.fee_amount,
        groupPaymentAutomatic: groupInfo?.payment_type,
        groupAutomaticPaymentDay: groupInfo?.membership_fee_deduction_date,
        isMonthly: groupInfo?.fee_type === "yearly", // Static value
        isPaid:groupInfo?.is_paid,
        groupPaymentField: groupInfo?.fee_amount,
        groupPaymentDescription: groupInfo?.fee_explanation,
        memberGroupUsers:groupInfo?.member_group_users
       
      })
    );
    navigate("/groupinforeview");
  };
  
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  // Debounce function to limit the number of API calls
  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let debounceTimer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  const handleSearch = useCallback((results: any) => {
    setGroups(results);
    // setReSearch(false);
  }, []);
  // Function to fetch search results
  const fetchSearchResults = async (term: string) => {
    if (term) {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel("Operation canceled due to new request.");
      }
      cancelTokenRef.current = axios.CancelToken.source();

      try {
        setLoading(true);
        const id=term.split('/groupinvitation?link=')[1];

        if(!id)return;

        const posts = await gcServices.searchById(id, {
          cancelToken: cancelTokenRef.current.token,
        });
        handleSearch(posts);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error fetching search results:", error);
          handleSearch([]);
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      handleSearch([]);
    }
  };
  // eslint-disable-next-line
  const debouncedFetchSearchResults = useCallback(
    debounce(fetchSearchResults, 800),
    []
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    debouncedFetchSearchResults(term);
  };
  useEffect(()=>{
    if (link) {
        setSearchTerm(link);
        debouncedFetchSearchResults(link);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[link])

  return (
    <div style={{ paddingTop: 100 }}>
         <GCBackButton title="back" onClick={() => navigate(-1)} />
      <div style={{ marginLeft: 10, marginRight: 10 }}>
      <>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="グループリンク..."
        value={searchTerm}
        onChange={handleChange}
        focused={true}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LinkIcon/>
            </InputAdornment>
          ),
          endAdornment: loading ? (
            <InputAdornment position="end">
              <CircularProgress size={24} />
            </InputAdornment>
          ) : null,
        }}
      />
    </>
      </div>
      <br />

      <Box display="flex" flexDirection="column">
        {memoizedGroups?.member_groups?.map((group: any, index: number) => (
          <IconButtonArrow
            key={index} // Use a unique key in a real app
            icon={
              <img
                alt=""
                height={26}
                width={26}
                style={{ borderRadius: 13 }}
                src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${group?.group_icon}`}
              />
            }
            title={group?.name || ""} // Title from the group
            role={group?.owner?.id===user?.id?'leader':group?.role}
            onClick={() => {
              handleGroup(group);
            }}
          />
        ))}
      </Box>
    </div>
  );
}

export default GroupInvite;
