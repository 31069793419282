import React from "react";
import { Box, Typography } from "@mui/material";
import TableComponent from "../GreenCity/TableComponent";
import Separator from "../Separator";
import { T } from "react-translator-component";
import { colors } from "../../common/colors";
import DropDown from "../DropDown";
import dayjs from "dayjs";

const EventApplicationDetails = ({
  detail,
  selectedValues,
  handleChange,
}: any) => {
  const createDropDown = (
    maxNumber: any,
    selectedValue: any,
    handleChange: any
  ) => {
    const options = Array.from({ length: maxNumber }, (_, i) => ({
      value: i + 1,
      name: `${i + 1}人`,
    }));
    options.unshift({ value: 0, name: "0人" });
    return (
      <DropDown
        title={``}
        value={selectedValue}
        options={options}
        onChange={handleChange}
      />
    );
  };

  return (
    <Box>
      <Box sx={{ marginTop: 4 }}>
        <Typography sx={{ marginBottom: 1, fontWeight: "bold" }}>
          {T("Event Details")}
        </Typography>
        <TableComponent
          data={[
            {
              col1: T("Remittance Destination"),
              col2: detail?.address,
            },
            {
              col1: T("Event name"),
              col2: detail?.venue_name,
            },
            {
              col1: T("Postal Code"),
              col2: detail?.postal_code,
            },
            {
              col1: T("Recruitment period"),
              col2: (
                <Box>
                  <Typography>
                    {detail?.application_start_date}{" "}
                    {dayjs(detail?.application_start_date).format("dddd")}
                  </Typography>
                  <Typography>
                    {detail?.application_end_date}{" "}
                    {dayjs(detail?.application_end_date).format("dddd")}
                  </Typography>
                </Box>
              ),
            },
          ]}
        />
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <Typography sx={{ marginBottom: 1, fontWeight: "bold" }}>
          {T("Participate in Projects")}
        </Typography>

        {detail.participant_settings &&
          detail.participant_settings.map((item: any, index: number) => (
            <Box
              sx={{
                padding: "10px 10px",
                backgroundColor: colors.farm_gray15,
              }}
              key={index}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Typography>
                    {item.name} :
                    {item.free ? " Free" : parseInt(item.fee_amount)}
                  </Typography>
                  {!item.group && (
                    <Typography sx={{ color: colors.farm_gray200 }}>
                      (残り{parseInt(item.number_of_participants)}個のみ)
                    </Typography>
                  )}
                </Box>
                <Box sx={{ marginTop: "-5px" }}>
                  {createDropDown(
                    item.group ? 1 : parseInt(item.number_of_participants),
                    selectedValues[index],
                    handleChange(index)
                  )}
                </Box>
              </Box>
              <Separator color={colors.farm_green100} sx={{ height: "2px" }} />
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default EventApplicationDetails;
