import React, { useState } from "react";
import { Avatar, Box, Typography,TableContainer,Table,TableHead,TableRow,TableCell,TableBody,Paper } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

function GroupMemberFee() {
    const navigate = useNavigate();
    const location = useLocation();
    const { groupInfo } = location?.state || {};
    const lastMonths = ['Jan 2024', 'Feb 2024', 'Mar 2024']; // Example months
    const lastYears= ['2021', '2022', '2023']; 
    const [users] = useState([
        { name: 'John Doe' },
        { name: 'Jane Smith' },
        { name: 'Michael Brown' },
        { name: 'Emily Davis' },
      ]);
    
    
  return (
    <Box>
        <Box style={style.headerContainer}>
            <GCBackButton title="もどる" onClick={() => navigate(-1)} />
                <Box
                    sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "10px",
                    }}
                >
                    <Box style={style.eventPostingBox}>
                    <Box component="div" style={style.eventPostingIcon}>
                        <Avatar src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`} sx={{ width: 30, height: 30 }} />
                    </Box>
                    <Typography style={{ fontWeight: "700", marginTop: "5px" }}>Payment Status</Typography>
                </Box>
            </Box>
        </Box>

        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell sx={{textAlign:"center"}} colSpan={2} >{groupInfo?.fee_type === "monthly" ? "毎月" : "毎年"}</TableCell>
                        {
                            groupInfo?.fee_type === "monthly" ? lastMonths.map((month)=> <TableCell sx={{textAlign:"center"}}>{month}</TableCell>) 
                            : lastYears.map((year)=><TableCell sx={{textAlign:"center"}}>{year}</TableCell>) 
                        }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users?.map((user, index) => (
                            <TableRow key={index}>
                                {/* Starting Index */}
                                <TableCell>{index + 1}</TableCell>

                                {/* User Name */}
                                <TableCell>{user.name}</TableCell>

                                {/* Ticks for the last 3 months or years */}
                                <TableCell style={{ textAlign: 'center' }}>
                                    <Typography variant="h6" color="gray">
                                        <CheckCircleOutline />
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <Typography variant="h6" color="gray">
                                        <CheckCircleOutline />
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <Typography variant="h6" color="gray">
                                        <CheckCircleOutline />
                                    </Typography>
                                </TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

    </Box>
  )
}
const style = {
    eventPostingIcon: {
      // background: "#FF3B00",
      borderRadius: "50%",
      padding: "3px 6px 3px 6px",
      color: "white",
    },
    eventPostingBox: {
      display: "flex",
      gap: "15px",
      alignItmes: "center",
      paddingTop: "10px",
      paddingLeft: "10px",
    },
    totalCount: {
      backgroundColor: "#F4F4F4",
      marginTop: 20,
      marginLeft: 15,
      marginRight: 15,
      marginBottom: 20,
      borderRadius: 10,
      padding: "15px 10px 15px 10px",
    },
    headerContainer: {
      paddingTop: 100,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: "12px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
    },
  };
export default GroupMemberFee