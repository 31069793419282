import { Avatar, Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import GCBackButton from '../../../components/GreenCity/GCBackButton';
import Separator from '../../../components/Separator';
import profile from "../../../assets/images/profile_img.png";
import { styles } from '../../styles';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import gcServices from '../../../services/app-services/greenCity/gcServicesR6';
import LoadingModal from '../../../components/LoadingModal';

function GroupRoles(): ReactElement {
    const location = useLocation();
    const navigate=useNavigate();
    const { groupInfo,member } = location?.state || {};
   


    const [role, setRole] = useState(member?.role || 'member');
const [loading, setLoading] = useState(false);
const [memberWithRole, setMemberRole] = useState<any>();

useEffect(() => {
  if (member?.id) {
    getRole(groupInfo?.id, member?.id);
  }
  console.log(groupInfo, member);
}, [groupInfo, member]);

const getRole = async (group_id: any, user_id: any) => {
  try {
    setLoading(true);
    const res = await gcServices.checkIsMembership(user_id, group_id);
    console.log(res);
    if (res?.member_group_users?.length > 0) {
      setMemberRole(res?.member_group_users[0]);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};

const handleRole = (event: React.ChangeEvent<HTMLInputElement>) => {
  setRole(event.target.value);
};

    useEffect(()=>{
        console.log('===',member?.role)
        if(member?.role){
            setRole(member?.role)
        }else{
            setRole('member')
        }

        if(member?.id){
            getRole(groupInfo?.id,member?.id)
        }

        console.log(groupInfo,member)
    },[groupInfo,member])


    // const getRole=async (group_id:any,user_id:any)=>{
    //     try {
    //         setLoading(true);
    //         const res=await gcServices.checkIsMembership(user_id,group_id) ;
    //         console.log(res)
    //         if(res?.member_group_users?.length>0){
    //            setMemberRole(res?.member_group_users[0])

    //         //    const updatedAt = res.member_group_users[0]?.updated_at;
    //         //    if (updatedAt) {
    //         //        const d = new Date(updatedAt);
    //         //        setDate(d);
    //         //    } else {
    //         //        console.error("updated_at is null or undefined.");
    //         //    }
    //         }
    //     } catch (error) {
            
    //     }finally{
    //         setLoading(false);
    //     }
   
   
    // }
  

    useEffect(()=>{
        console.log('LL===',memberWithRole?.role)
        setRole(memberWithRole?.role)
    },[memberWithRole])

const handleSave=async ()=>{
    console.log('here')
  
    try {
        setLoading(true);
        if(memberWithRole?.id){
            console.log(memberWithRole?.id);
            console.log(role)
            const res= await gcServices.updateMemberRoleOnly(memberWithRole?.id,role) ;
            console.log(res)
        }
      
        
    } catch (error) {
        
    }finally{

        setLoading(false);
    }

}

    // const handleRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     //public private
    //     setRole((event.target as HTMLInputElement).value);
    //   };


      
    
  const radioGroupContent = useMemo(() => {
    console.log('////',role)
    return (
      <RadioGroup
        aria-labelledby="payment-radio-buttons-group-label"
        value={role}
        onChange={handleRole}
        name="radio-buttons-group"
      >
        <FormControlLabel
          value="leader"
          control={<Radio sx={{ alignSelf: "flex-start" }} />}
          label={
            <div>
              <Typography variant="body1">リーダー</Typography>
              <Typography variant="body2" color="textSecondary">
                みんなの投稿への投稿、グループ内の投稿、グループ内のプライベート投稿、コメント、コメントバック、グループ参加希望者の承認、イベント参加希望者の承認、支払い全般の承認、めぶくナビゲーターへの連絡、グループアイコンの変更、グループ紹介ページの変更、メンバーの強制脱退、グループの削除
              </Typography>
              <br />
            </div>
          }
          sx={{ alignItems: "flex-start" }}
        />
        <Separator sx={{ marginTop: "10px", marginBottom: "10px" }} color="gray" />
        <FormControlLabel
          value="sub-leader"
          control={<Radio sx={{ alignSelf: "flex-start" }} />}
          label={
            <div>
              <Typography variant="body1">サブリーダー</Typography>
              <Typography variant="body2" color="textSecondary">
                みんなの投稿への投稿、グループ内の投稿、グループ内のプライベート投稿、コメント、コメントバック、グループ参加希望者の承認、イベント参加希望者の承認、支払い全般の承認、めぶくナビゲーターへの連絡、グループアイコンの変更、グループ紹介ページの変更
              </Typography>
            </div>
          }
          sx={{ alignItems: "flex-start" }}
        />
        <Separator sx={{ marginTop: "10px", marginBottom: "10px" }} color="gray" />
        <FormControlLabel
          value="finance"
          control={<Radio sx={{ alignSelf: "flex-start" }} />}
          label={
            <div>
              <Typography variant="body1">ファイナンス</Typography>
              <Typography variant="body2" color="textSecondary">
                グループ内のプライベート投稿、コメント、コメントバック、支払い全般の承認
              </Typography>
            </div>
          }
          sx={{ alignItems: "flex-start" }}
        />
        <Separator sx={{ marginTop: "10px", marginBottom: "10px" }} color="gray" />
        <FormControlLabel
          value="member"
          control={<Radio sx={{ alignSelf: "flex-start" }} />}
          label={
            <div>
              <Typography variant="body1">役割なし</Typography>
              <Typography variant="body2" color="textSecondary">
                一般のユーザーとなります。
              </Typography>
            </div>
          }
          sx={{ alignItems: "flex-start" }}
        />
      </RadioGroup>
    );
  }, [role]);

  return (
    <div style={style.headerContainer}>
         <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
       <Box style={style.eventPostingBox}>
      
            <Box component="div" style={style.eventPostingIcon}>
              <Avatar   src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`} sx={{ width: 30, height: 30 }} />
            </Box>
            <Typography style={{ fontWeight: "700", marginTop: "5px" }}>
                {groupInfo?.name}
                <span>チャットリスト</span>
            </Typography>



          </Box>
          <Box style={{display:'flex',flexDirection:'row'}}>

          <Box component="div" style={style.eventPostingIcon}>
              <Avatar  src={
              member.image !== null
                ? "data:image/jpeg;base64," + member.image
                : profile
            } sx={{ width: 30, height: 30 }} />
            </Box>
            <Typography style={{ fontWeight: "700", marginTop: "5px" }}>
                {member?.nickname}
                
            </Typography>

          </Box>
          <Separator sx={{ marginTop: "10px",marginBottom: "10px" }} color="gray" />

          <Box style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <Box>
                    <Typography style={{fontSize:10}}>本名</Typography>
                    <Typography>{member?.name}</Typography>
                </Box>
                <Box>
                    <Typography style={{fontSize:10}}>権限</Typography>
                    <Typography>{memberWithRole?.role}</Typography>
                </Box>
                <Box>
                    <Typography style={{fontSize:10}}>権限付与日</Typography>
                    <Typography>{memberWithRole?.updated_at?.split('T')[0]}</Typography>
                </Box>
          </Box>

          <Separator sx={{ marginTop: "10px",marginBottom: "10px" }} color="gray" />

          <Box>
          <>{radioGroupContent}</>
          {/* <RadioGroup
              aria-labelledby="payment-radio-buttons-group-label"
              value={role}
              onChange={handleRole}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="leader"
                control={<Radio sx={{ alignSelf: "flex-start" }} />} // Aligns the radio button to the top
                label={
                  <div>
                    <Typography variant="body1">
                    リーダー
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                    みんなの投稿への投稿、グループ内の投稿、グループ内のプライベート投稿、コメント、コメントバック、グループ参加希望者の承認、イベント参加希望者の承認、支払い全般の承認、めぶくナビゲーターへの連絡、グループアイコンの変更、グループ紹介ページの変更、メンバーの強制脱退、グループの削
除
                    </Typography>
                    <br />
                  </div>
                }
                sx={{ alignItems: "flex-start" }} // Aligns the whole label to the top
              />
               <Separator sx={{ marginTop: "10px",marginBottom: "10px" }} color="gray" />
              <FormControlLabel
                value="sub-leader"
                control={<Radio sx={{ alignSelf: "flex-start" }} />}
                label={
                  <div>
                    <Typography variant="body1">
                    サブリーダー
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                    みんなの投稿への投稿、グループ内の投稿、グループ内のプライベート投稿、コメント、コメントバック、グループ参加希望者の承認、イベント参加希望者の承認、支払い全般の承認、めぶくナビゲーターへの連絡、グループアイコンの変更、グループ紹介
ページの変
                    </Typography>
                  </div>
                }
                sx={{ alignItems: "flex-start" }}
              />
               <Separator sx={{ marginTop: "10px",marginBottom: "10px" }} color="gray" />
               <FormControlLabel
                value="finance"
                control={<Radio sx={{ alignSelf: "flex-start" }} />}
                label={
                  <div>
                    <Typography variant="body1">
                    ファイナンス
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                    グループ内のプライベート投稿、コメント、コメントバック、支払い全般の承認
                    </Typography>
                  </div>
                }
                sx={{ alignItems: "flex-start" }}
              />
 <Separator sx={{ marginTop: "10px",marginBottom: "10px" }} color="gray" />
        <FormControlLabel
                value="member"
                control={<Radio sx={{ alignSelf: "flex-start" }} />}
                label={
                  <div>
                    <Typography variant="body1">
                    役割なし
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                    一般のユーザーとなります。
                    </Typography>
                  </div>
                }
                sx={{ alignItems: "flex-start" }}
              />
            </RadioGroup> */}

          </Box>
          <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                    handleSave();
                }}
                style={{
                  ...styles.introSliderBtn,
                  marginTop: 40,
                  width: 220,
                }}
                size={"large"}
                variant="contained"
                endIcon={<ArrowForwardIosIcon />}
              >
                <span style={{ paddingRight: 30, paddingLeft: 40 }}>
                  保存
                </span>
              </Button>
              <Box style={{ height: 100 }}></Box>
            </Box>
            <LoadingModal visible={loading} handleClose={() => console.log("")} />
    </div>
  )
}



export default GroupRoles

const style = {
   
    eventPostingIcon: {
      borderRadius: "50%",
      padding: "3px 6px 3px 6px",
      color: "white",
    },
    eventPostingBox: {
      display: "flex",
      gap: "15px",
      alignItems: "center",
      paddingTop: "10px",
      paddingLeft: "10px",
    },
    headerContainer: {
        paddingTop: 100,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: "12px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
      },
   
  };
  
