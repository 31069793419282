import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, CardContent, ListItem, Stack, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { useSelector } from "react-redux";
import appServices from "../../../services/app-services/appServices";
import MessageModal from "../../../components/Models/GreenCity/MessageModal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PushPinIcon from "@mui/icons-material/PushPin";
import OptionsModal2 from "../../../components/Models/OptionsModal2";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import { ChatList } from "../../../util/types";
import LoadingModal from "../../../components/LoadingModal";
import { getChatUserName } from "../../../util/utils";

const ChatMemberList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { groupInfo } = location?.state || {};
  const { user } = useSelector((state: any) => state.userAuth);

  const [allChats, setAllChats] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // const [chatMembers, setMembers] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isGroupChatExists, setIsGroupChatExists] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groupChatInfo, setGroupChatInfo] = useState<any>(null);
  const [openOption, setOpenOptions] = useState(false);
  const [openSingleOption, setOpenSingleOption] = useState(false);
  const [selectedChat, setSelectedChat] = useState<any>(null);
  const [selectedAvatar, setSelectedAvatar] = useState<string | undefined>(undefined);
  const [selectedName, setSelectedName] = useState<string>();

  const checkIfGroupChatExists = () => {
    appServices.showGroupChat(groupInfo?.id).then((response: any) => {
      let isGroupChatExists = response?.chats.find((chat: any) => chat.chat_type === "group");
      setIsGroupChatExists(isGroupChatExists ? true : false);
      setGroupChatInfo(isGroupChatExists);
      // console.log("response",response?.chats[0]);
    });
  };

  const getGroupMembers = async () => {
    const res = await gcServices.getMemberList(groupInfo?.id);
    setGroupMembers(res?.member_group_users);
  };

  const getChatList = () => {
    gcServices
      .getChatList(groupInfo?.id)
      .then((response: any) => {
        // console.log("Chat List", response);
        setAllChats(response.chats.filter((chat: any) => chat.chat_type !== "group"));
        setLoading(false);
      })
      .catch((error: any) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  // eslint-disable-next-line
  useEffect(() => {
    setLoading(true);
    checkIfGroupChatExists();
    getGroupMembers();
    getChatList();
    // eslint-disable-next-line
  }, [groupInfo]);

  const getMemberImage = (memberId: number) => {
    const memberInfo = groupInfo?.member_group_users?.find((groupMember: any) => groupMember.id === memberId);
    // console.log(memberInfo, "sssssssssssssssssssss");

    if (!memberInfo?.image) return undefined;
    return `${memberInfo.image}`;
  };

  const handleOptionsModal = () => {
    setOpenOptions(!openOption);
  };

  const handleGroupSelection = (path: string) => {
    // console.log(path);
    if (path === "/userchat") {
      // navigate("/userchat", { state: { groupInfo: groupChatInfo } });
      navigate("/userchat", {
        state: {
          chatId: groupChatInfo?.id,
          chatUsers: groupChatInfo?.users.map((member: any) => ({
            id: member.id,
            name: member.name,
            nickname: member.nickname,
            image: getMemberImage(member.id),
            can_message: member.can_message,
          })),
          chat_type: groupChatInfo?.chat_type,
          groupMembers: groupMembers,
          groupInfo: groupInfo,
          isCreator: groupInfo?.owner?.id === user.id,
          isGroupChat: true,
        },
      });
    } else if (path === "openChat") {
      navigate("/userchat", {
        state: {
          chatId: selectedChat.id,
          chatUsers: selectedChat?.users.map((member: any) => ({
            id: member.id,
            name: member.name,
            nickname: member.nickname,
            image: getMemberImage(member.id),
            can_message: member.can_message,
          })),
          chat_type: groupChatInfo?.chat_type,
          groupMembers: groupMembers,
          groupInfo: groupInfo,
          isCreator: selectedChat?.creator_id === user.id,
          isGroupChat: true,
        },
      });
    }
  };

  // Define static options
  const staticOptions = [
    // { title: "Option 1", path: "option1", disabled: false },
    { title: "グループチャット", path: "/userchat", disabled: false },
    // { title: "Option 3", path: "option3", disabled: false },
  ];

  const getChatOption = () => {
    const isOwner = groupInfo?.owner?.id === user.id;
    let chatOption = [
      { title: "ユーザーのページにアクセスする", path: "", disabled: false },
      { title: "チャット", path: "openChat", disabled: false },
      // Options that are available for the group owner
      { title: "役割を変える", path: "/switchingRole", disabled: !isOwner },
      { title: "グループチャットに追加する", path: "/addNewUser", disabled: !isOwner },
      { title: "強制撤退", path: "", disabled: !isOwner },
    ];
    return chatOption;
  };

  return (
    <Box>
      <Box style={style.headerContainer}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box style={style.eventPostingBox}>
            <Box component="div" style={style.eventPostingIcon}>
              <Avatar src={`${groupInfo?.groupImage}`} sx={{ width: 30, height: 30 }} />
            </Box>
            <Typography style={{ fontWeight: "700", marginTop: "5px" }}>
              {groupInfo?.name}
              <span>チャットリスト</span>
            </Typography>
          </Box>
        </Box>

        <Box>
          {isGroupChatExists && (
            <ListItem disableGutters>
              <Card sx={{ width: "100%", boxShadow: "none" }}>
                <CardContent sx={{ paddingBottom: "0px !important" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={groupInfo?.groupImage ? `data:image/jpeg;base64,${groupInfo.groupImage}` : undefined} sx={{ width: 40, height: 40 }} />
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {groupInfo?.name}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
              {groupChatInfo?.users?.some((groupuser: any) => groupuser?.id === user?.id) ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PushPinIcon sx={{ color: "grey", transform: "rotate(45deg)", fontSize: "28px" }} />
                  <Box sx={{ margin: "0 5px" }} style={style.optionButton} onClick={handleOptionsModal}>
                    <MoreHorizIcon sx={{ color: "#4792E0", cursor: "pointer" }} />
                  </Box>
                </Box>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center", opacity: 0.5 }}>
                  <PushPinIcon sx={{ color: "grey", transform: "rotate(45deg)", fontSize: "28px" }} />
                  <Box sx={{ margin: "0 5px", border: "1px solid gray", borderRadius: "50%", padding: "3px" }} onClick={() => {}}>
                    <MoreHorizIcon sx={{ color: "gray", cursor: "pointer" }} />
                  </Box>
                </Box>
              )}

              <OptionsModal2 open={openOption} onClose={handleOptionsModal} options={staticOptions} onSelect={handleGroupSelection} />
            </ListItem>
          )}
        </Box>
        {allChats.map((chat: ChatList) => {
          return (
            <Box>
              <ListItem sx={{borderTop:"1px solid #D3D3D3"}} disableGutters>
                <Card sx={{ width: "100%", boxShadow: "none", }}>
                  <CardContent sx={{ paddingBottom: "0px !important",paddingTop:"0" }}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar 
                        src={getMemberImage(chat?.users[1]?.id) ? 
                          "data:image/jpeg;base64," + getMemberImage(chat?.users[1]?.id) 
                          : undefined} 
                        sx={{ width: 40, height: 40 }} 
                      />
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {getChatUserName(chat?.users, user.id)}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{ margin: "0 5px" }}
                    style={style.optionButton}
                    onClick={() => {
                      setOpenSingleOption(true);
                      setSelectedChat(chat);
                      const selectedUser = chat.users[1]; // Assuming you want the second user
                      const memberImage = getMemberImage(selectedUser.id);
                      setSelectedAvatar("data:image/jpeg;base64,"+memberImage); // No need for null conversion
                      setSelectedName(selectedUser.name); // Set name
                    }}
                  >
                    <MoreHorizIcon sx={{ color: "#4792E0", cursor: "pointer" }} />
                  </Box>
                </Box>

                <OptionsModal2 
                open={openSingleOption} 
                onClose={() => setOpenSingleOption(false)} 
                options={getChatOption()} 
                onSelect={handleGroupSelection}
                avatar={selectedAvatar} // Pass avatar separately
                userName={selectedName} // Pass name separately
                 />

              </ListItem>
            </Box>
          );
        })}
      </Box>
      <LoadingModal visible={loading} handleClose={() => {}} />
      <MessageModal onContinue={() => console.log("clicked")} open={errorMessage.length > 0} message={errorMessage} onClose={() => setErrorMessage("")} />
    </Box>
  );
};

const style = {
  optionButton: {
    border: "1px solid #4792E0",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px",
  },
  eventPostingIcon: {
    borderRadius: "50%",
    padding: "3px 6px 3px 6px",
    color: "white",
  },
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  totalCount: {
    backgroundColor: "#F4F4F4",
    marginTop: 20,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 20,
    borderRadius: 10,
    padding: "15px 10px 15px 10px",
  },
  headerContainer: {
    paddingTop: 100,
    // paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: "12px",
    // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
};

export default ChatMemberList;

//   <ListItem disableGutters>
//   <UserCardList
//     currentUserId={user?.id}
//     user={member}
//     userName={member?.name}
//     onChatPress={() => handleChatPress(member)}
//     onApprove={() => setMember(member)}
//     onDelete={() => handleDelete(member.id)}
//     onMoreOptions={() => handleMoreOptions(member.id)}
//     showApproveIcon={false}
//     userAvatar={getMemberImage(member.id)}
//   />
// </ListItem>
