import { Typography } from '@mui/material';
import React, { ReactElement } from 'react'
import { colors } from '../../common/colors';

interface Props {
  fieldTitle?:string;
  fieldData?:string;
}

function PIIField({fieldTitle,fieldData}: Props): ReactElement {
  return (
    <div style={{paddingTop:10}}>
      <Typography style={{fontSize:12}}>{fieldTitle}</Typography>
      <Typography style={{backgroundColor:colors.farm_gray200,padding:4}}>{fieldData}</Typography>
    </div>
  )
}

export default PIIField
