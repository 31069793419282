import { Modal, Box, Typography } from "@mui/material";
interface PaymentConfirmationModalProps {
  open: boolean;
  onClose?: () => void;
}

const PaymentConfirmationModal: React.FC<PaymentConfirmationModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <Box>
      <Modal
        style={{ zIndex: 9999 }}
        open={open}
        //   onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box>
          <Box sx={style}>
            <Typography>送金しました</Typography>
          </Box>
          <Box sx={style2}>
            <Typography sx={{ textAlign: "left", fontWeight: 600 }}>
              その他考えられそうな文言{" "}
            </Typography>
            <ul>
              <li>
                残高不足のため送金できませ んでした。めぶくPayでチャー
                ジ後あたらめてお申し込みくだ さい。
              </li>
              <li>エラー</li>
              <li>お申し込み済みです。</li>
            </ul>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "52%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "20px 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 2,
};
const style2 = {
  position: "absolute" as "absolute",
  top: "75%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "20px 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default PaymentConfirmationModal;
