import { Avatar, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chat from "../../components/GreenCity/Chat";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import appServices from "../../services/app-services/appServices";
import { useEffect, useState } from "react";
import { Message } from "../../util/types";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
const GroupChat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { chatId, chatUsers, groupInfo } = location.state || {};
  const { user } = useSelector((state: any) => state.userAuth);

  const [allMessages, setAllMessages] = useState<Message[]>([]);
  const [currentChatUsers, setCurrentChatUsers] = useState(chatUsers || []);
  const [members, setMembers] = useState<any[]>([]);
  const [pendingMessage, setPendingMessage] = useState<string | null>(null);
  const [lastMessageId, setLastMessageId] = useState<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openOption, setOpenOption] = useState(false);
  // const [options, setOptions] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [confirmDeleteUser, setConfirmDeleteUser] = useState<number | null>(
    null
  );
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isCreator, setIsCreator] = useState(false);

  useEffect(() => {
    apiCall();
    getChatMessages();
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => getNewMessages(true), 10000);
    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessageId]);

  const apiCall = async () => {
    try {
      const res = await gcServices.getMemberList(groupInfo?.id);
      setMembers(res?.member_group_users);
      setIsCreator(res?.group?.creator_id === user.id);

      const updatedChatUsers = chatUsers?.map((chatUser: any) => {
        const memberInfo = res?.member_group_users.find(
          (member: any) => member.user.id === chatUser.id
        );
        return {
          ...chatUser,
          ...memberInfo?.user,
        };
      });
      setCurrentChatUsers(updatedChatUsers || []);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  const getChatMessages = () => {
    appServices.getChatMessages(chatId).then((res) => {
      setAllMessages(res.data.chat.messages);
      if (res.data.chat.messages.length > 0) {
        setLastMessageId(
          res.data.chat.messages[res.data.chat.messages.length - 1].id
        );
      }
    });
  };

  const getNewMessages = (checkforLastMessageId: boolean = true) => {
    if (checkforLastMessageId && !lastMessageId) return;

    appServices
      .getLatestMessages(chatId, lastMessageId || 0, user.auth_token)
      .then((res) => {
        if (res.data.messages.length > 0) {
          setAllMessages((prev) => [...prev, ...res.data.messages]);
          setLastMessageId(res.data.messages[res.data.messages.length - 1].id);
        }
        setIsLoading(false);
        setPendingMessage(null);
      });
  };

  const handleSendMessage = (message: string) => {
    setPendingMessage(message);
    setIsLoading(true);
    appServices
      .sendMessage(message, user.auth_token, chatId)
      .then(() => {
        getNewMessages(false);
      })
      .catch((err) => {
        console.error("Error sending message:", err);
        setIsLoading(false);
        setPendingMessage(null);
      });
  };

  const checkScrollArrows = () => {
    const container = document.getElementById("members-container");
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  const handleScroll = (direction: "left" | "right") => {
    const container = document.getElementById("members-container");
    if (container) {
      const scrollAmount = 100; // Pixels to scroll each click
      const newPosition =
        direction === "left"
          ? Math.max(0, scrollPosition - scrollAmount)
          : Math.min(
              container.scrollWidth - container.clientWidth,
              scrollPosition + scrollAmount
            );

      container.scrollTo({
        left: newPosition,
        behavior: "smooth",
      });
      setScrollPosition(newPosition);

      // Check arrow visibility after scroll animation
      setTimeout(checkScrollArrows, 300);
    }
  };

  // Add this useEffect to initialize and handle scroll arrows
  useEffect(() => {
    checkScrollArrows();
    window.addEventListener("resize", checkScrollArrows);
    return () => window.removeEventListener("resize", checkScrollArrows);
  }, [currentChatUsers]);

  // Add scroll event listener to the container
  useEffect(() => {
    const container = document.getElementById("members-container");
    if (container) {
      container.addEventListener("scroll", checkScrollArrows);
      return () => container.removeEventListener("scroll", checkScrollArrows);
    }
  }, []);

  // const getUserImage = (userId: number) => {
  //   const member = members.find((m) => m.user.id === userId);
  //   return member?.user?.image || "";
  // };

  const handleOptionsModal = () => {
    setOpenOption(true);
  };

  return (
    <Box>
      <Box sx={style.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={style.groupContainer}>
          <Box sx={style.scrollContainer}>
            {showLeftArrow && (
              <ArrowBackIosIcon
                sx={style.scrollIcon}
                onClick={() => handleScroll("left")}
              />
            )}
            <Box id="members-container" sx={style.membersWrapper}>
              <Box sx={style.memberContainer}>
                {members?.map(
                  (member: any) =>
                    member.user.id !== user.id && (
                      <Box
                        onClick={() =>
                          isCreator && setConfirmDeleteUser(member.user.id)
                        }
                        key={member.user.id}
                        sx={style.eventPostingBox}
                      >
                        <Avatar
                          src={"data:image/jpeg;base64," + member.user.image}
                          sx={{ width: 30, height: 30 }}
                        />
                        <Typography sx={style.memberTitle}>
                          {member.user.name}
                        </Typography>
                      </Box>
                    )
                )}
              </Box>
            </Box>
            {showRightArrow && (
              <ArrowForwardIosIcon
                sx={style.scrollIcon}
                onClick={() => handleScroll("right")}
              />
            )}
          </Box>
          <AddCircleOutlineIcon
            sx={style.addIcon}
            onClick={handleOptionsModal}
          />
        </Box>
      </Box>
      <Chat
        currentUser={user}
        messages={allMessages}
        handleSendMessage={handleSendMessage}
        pendingMessage={pendingMessage}
      />
      {/* Modals */}
    </Box>
  );
};

export default GroupChat;

const style = {
  headerBox: {
    padding: "10px 16px",
    paddingTop: "100px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
  groupContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "10px",
  },
  memberContainer: {
    display: "flex",
    gap: "10px",
    width: "max-content",
    marginTop: "5px",
  },
  eventPostingBox: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    paddingTop: "3px",
    paddingLeft: "5px",
  },
  eventPostingIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px",
    color: "white",
  },
  memberTitle: {
    fontWeight: "700",
    // marginTop: "5px",
  },
  addIcon: {
    color: "#4792E0",
    fontSize: "35px",
    cursor: "pointer",
  },
  scrollContainer: {
    display: "flex",
    alignItems: "center",
    width: "80%",
    gap: "8px",
  },
  membersWrapper: {
    width: "100%",
    overflow: "hidden",
  },
  scrollIcon: {
    color: "#4792E0",
    cursor: "pointer",
    fontSize: "16px",
    flexShrink: 0,
    "&:hover": {
      opacity: 0.7,
    },
  },
};
