import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../../common/colors';
import GCBackButton from '../../../../components/GreenCity/GCBackButton';
import Separator from '../../../../components/Separator';
import { styles } from '../../../styles';
import { faCopy } from '@fortawesome/free-regular-svg-icons'; 
import QRCode from "react-qr-code";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
// eslint-disable-next-line
function InvitionLinkCreations(){

    const navigate = useNavigate();
    const groupInfo = useSelector((state: any) => state.gcGroup);
    
    const link=`${process.env.REACT_APP_API_URL?.replace("api.",'')}/groupinvitation?link=${groupInfo?.id}`

    
        const copyToClipboard = () => {
            navigator.clipboard.writeText(link)
                .then(() => alert('Copied successfully!'))
                .catch(err => alert('Failed to copy: ' + err));
        };
  return (
    <div style={{ paddingTop: 100, marginLeft: 16 }}>
    <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
    <Box style={{ padding: 16 }}>
      <Typography style={{ fontWeight: "700" }}>
        <img
          src={`${groupInfo?.groupImage}`}
          alt={"user_img"}
          style={{
            ...styles.farmProfileIcon,
            height: 50,
            width: 50,
            margin: 0,
          }}
        />
        &nbsp;{groupInfo?.groupName}
      </Typography>
    </Box>

   
    <Separator sx={{ marginTop: "4px" , marginBottom: "4px" }} color={colors.farm_gray100} />
      <Typography
              style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                fontSize: 14,
                marginBottom: 6,
                marginTop: 16,
              }}
            >
              <PeopleAltOutlinedIcon />
              &nbsp;グループへ招待する
            </Typography>
            <Typography>QRコード</Typography>
            <Typography style={{fontSize:12,color:'#747474'}}>メンバーになってもらいたい人に「めぶくアプリ」のパスポートから、下記QRコードを読み込んでもらいます。読み込み後メンバーとなります。</Typography>
      <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
        <QRCode
          size={280}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={link}
          viewBox={`0 0 256 256`}
        />
      </div>
      <br/>
      <Separator sx={{ marginTop: "4px" , marginBottom: "4px" }} color={colors.farm_gray100} />
      <br></br>
      
      <Typography>招待リンクを送る</Typography>
      <Typography style={{fontSize:12,color:'#747474'}}>下記ボタンをクリックすると、招待リンクがコピーされますので、メールやSNS等にペーストしてメンバーになってもらいたい人宛に送ってください。招待リンクをクリックすると、メンバーとなります。</Typography>
    <br/>
    <br/>
    <Typography style={{fontSize:12,color:'#747474'}}>{link}</Typography>
      <Button
        color="secondary"
        sx={style.submitButton}
        size={"large"}
        variant="outlined"
        onClick={copyToClipboard}
        endIcon={<FontAwesomeIcon style={{ position: "absolute", top: 0, right: 0, margin: "10px" }} icon={faCopy} />}
      >
        招待リンクをコピー
      </Button>
    
    </div>
  )
}

export default InvitionLinkCreations

const style = {
   
    submitButton: {
      width: "80%",
      alignSelf: "center",
      margin: "0 auto",
      display: "flex",
      borderRadius: 20,
      border: "2px solid",
      marginBottom: 8,
    },
  };