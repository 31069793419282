import React, {  ReactElement } from "react";
import { Typography } from "@mui/material";
interface Props {
  onClick(): void;
  title: string;
  Icon: any;
}

function TextButton({ onClick, title, Icon }: Props): ReactElement {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {Icon && <Icon />}
      <Typography style={{ fontSize: 14, marginLeft: 10 }}>{title}</Typography>
    </div>
  );
}

export default TextButton;
