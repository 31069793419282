import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import ButtonOutlinedCentered from '../../ButtonOutlined';
import ButtonPrimary from '../../ButtonPrimary';

// Define the props interface
interface PIIConfirmationProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const PIIConfirmation: React.FC<PIIConfirmationProps> = ({ open, onClose, onContinue }) => { 

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth:400,
          height:'80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
        開示
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
        このグループは以下の個人情報の提供をを求めています。同意しますか？
        </Typography>
        <br/>
       
        <Typography variant="body1" sx={{ mb: 2 ,textAlign:'center'}}>
        ・氏名
        </Typography>
        {/* <Typography variant="body1" sx={{ mb: 2 ,textAlign:'center'}}>
        ・生年月日
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 ,textAlign:'center'}}>
        ・住所
        </Typography> */}

    
        <ButtonPrimary title='同意して進む'  onClick={onContinue}/>
        <ButtonOutlinedCentered title='同意して進む'  onClick={onClose}/>

       
      </Box>
    </Modal>
  );
};




export default PIIConfirmation
