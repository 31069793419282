import React, { ReactElement, useEffect } from "react";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import TextButton from "../../components/GreenCity/TextButton";
import Separator from "../../components/Separator";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import appServices from "../../services/app-services/appServices";
import EventDetailsList from "../../components/lists/EventDetailsList";
import LoadingModal from "../../components/LoadingModal";
import { useSelector } from "react-redux";

const AddIcon = () => {
  return (
    <div style={styles.addIcon}>
      <AddRoundedIcon sx={{ width: 20, height: 20 }} />
    </div>
  );
};
function GroupEventSettingPage(): ReactElement {
  const navigate = useNavigate();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const { user } = useSelector((state: any) => state.userAuth);

  const [events, setEvents] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [groupInformation] = React.useState(groupInfo);
  const getEvents = async () => {
    setLoading(true);
    const response = await appServices.getGroupEvents(groupInformation.id);
    setEvents(response.posts);
    setLoading(false);
  };

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ paddingTop: 100 }}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />

      <Box component="div" style={styles.topButtonContainer}>
        <div>
          <Typography style={styles.everyonePostStyle}>イベント投稿</Typography>
        </div>
        {groupInfo?.owner?.id === user?.id && (
          <TextButton
            onClick={() =>
              navigate("/createnewevent", {
                state: {
                  groupInfo: groupInfo,
                },
              })
            }
            Icon={AddIcon}
            title="投稿する"
          />
        )}
      </Box>
      <Separator sx={{ marginTop: "10px" }} color="gray" />
      {events?.map((event: any) => (
        <Box key={event.id}>
          <EventDetailsList
            items={[event]}
            page={""}
            order={""}
            handleReport={function (post_id: number, status: any): void {
              throw new Error("Function not implemented.");
            }}
            onLike={function (post_id: number): void {
              throw new Error("Function not implemented.");
            }}
            onUnLike={function (post_id: number): void {
              throw new Error("Function not implemented.");
            }}
            likeLoading={false}
          />
        </Box>
      ))}
      <LoadingModal visible={loading} handleClose={() => {}} />
    </div>
  );
}

const styles = {
  container: {
    paddingTop: 90,
  },
  addIcon: {
    border: "2px solid",
    borderRadius: 5,
    borderColor: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: "0 10px",
  },

  everyonePostStyle: {
    fontSize: 16,
  },
};

export default GroupEventSettingPage;
