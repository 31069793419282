import React from 'react';
import { Box, Typography, Modal } from '@mui/material';

interface AddUserSuccessModalProps {
  open: boolean;
  onClose: () => void;
}

const AddUserSuccessModal: React.FC<AddUserSuccessModalProps> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="success-modal"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: '300px',
          outline: 'none'
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          User Added Successfully
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddUserSuccessModal;