import { transformData } from "../../common/utils/transform";
import axiosService from "../axios-util/axios-services";
import { Createchat, Updatechat } from "../../util/types";

const getGroups = async (page: number) => {
  const response = await axiosService.get(`groups?per_page=20&page=${page}`);
  const groups = response.data.groups.map(
    (item: { id: any; name: any; posts_count: any }) => {
      return {
        id: item.id,
        value: item.id,
        name: item.name,
        post_count: item.posts_count,
        pagination: response.data?.pagination,
      };
    }
  );

  return groups;
};
const getGroupsApp = async (page: number) => {
  //change for app
  const response = await axiosService.get(`groups?&page=${page}`);
  // const groups = response.data.groups.map(
  //   (item: { id: any; name: any; posts_count: any }) => {
  //     return {
  //       id: item.id,
  //       value: item.id,
  //       name: item.name,
  //       post_count: item.posts_count,
  //       pagination:response.data?.pagination
  //     };
  //   }
  // );

  return response.data;
};
const getUsers = async (page: number) => {
  const response = await axiosService.get(
    `users?q[source_eq]=admin&page=${page}`
  );
  // const users = response.data.users.map((item: { id: any; name: any }) => {
  //   return {
  //     id: item.id,
  //     value: item.id,
  //     name: item.name,
  //   };
  // });

  return response.data;
};

const getPendingPosts = async (page: number) => {
  // const user = await userAuth.getUser();
  // posts?q[status_in][]=pending&q[status_in][]=reported&page=${page}
  // posts?q[status_eq]=pending&page=${page}
  const response = await axiosService.get(
    `posts?q[status_in][]=pending&q[status_in][]=reported&page=${page}`
  );
  return response.data;
};
const getReportedPosts = async (page: number) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `posts?q[status_eq]=pending&q[status_eq]=reported&page=${page}`
  );
  return response.data;
};

const getDashboard2 = async () => {
  const response = await axiosService.get(
    `external/users/interval_activity_counts`
  );

  return response.data;
};

const getDashboard3 = async () => {
  const response = await axiosService.get(`external/posts/dashboard`);

  return response?.data?.categories;
};
const getDashboard = async () => {
  const response = await axiosService.get(`external/posts/dashboard`);
  console.log(response.data);
  interface PostType {
    [key: string]: number; // Add index signature to allow indexing with a string
  }

  interface Category {
    id: number;
    name: string;
    jp_name: string; // Add Japanese name here
    post_types: {
      [key: string]: PostType;
    };
  }

  interface TransformedData {
    zero: {
      category: string;
      jp_name: string;
      data: { name: string; jp_name: string; count: number }[];
    }[];
    one: {
      category: string;
      jp_name: string;
      data: { name: string; jp_name: string; count: number }[];
    }[];
  }

  const transformedData: TransformedData = { zero: [], one: [] };

  response.data.categories.forEach((category: Category) => {
    const categoryData0: { name: string; jp_name: string; count: number }[] =
      [];
    const categoryData1: { name: string; jp_name: string; count: number }[] =
      [];

    // Define English and Japanese names
    const names = [
      { name: "posts", jp_name: "投稿" },
      { name: "views", jp_name: "ビュー" },
      { name: "post_likes", jp_name: "いいね！" },
      { name: "votes", jp_name: "投票" },
      { name: "comments", jp_name: "コメント" },
    ];

    // Extract data for post type 0
    const postType0 = category.post_types["0"];
    names.forEach(({ name, jp_name }) => {
      if (name !== "views")
        categoryData0.push({ name, jp_name, count: postType0[name] });
    });

    // Extract data for post type 1
    const postType1 = category.post_types["1"];
    names.forEach(({ name, jp_name }) => {
      if (name !== "views")
        categoryData1.push({ name, jp_name, count: postType1[name] });
    });

    transformedData.zero.push({
      category: category.name,
      jp_name: category.jp_name,
      data: categoryData0,
    });
    transformedData.one.push({
      category: category.name,
      jp_name: category.jp_name,
      data: categoryData1,
    });
  });

  return transformedData;
};

const getPostsInterval = async () => {
  const response = await axiosService.get(
    `external/posts/interval_activity_counts`
  );

  const data = transformData(response.data);
  console.log("========>>>><<<<<<++++++", data);
  return data;
};
const getSuggestionsInterval = async () => {
  const response = await axiosService.get(
    `external/posts/interval_activity_counts`
  );

  return response.data;
};

const getPendingComments = async (page: number) => {
  const response = await axiosService.get(
    `comments?q[status_eq]=pending&page=${page}`
  );
  return response.data;
};

const acceptRejectPost = async (post_id: number, status: any) => {
  // reject approve
  const response = await axiosService.patch(`posts/${post_id}/${status}`);

  return response;
};
const acceptRejectComment = async (comment_id: any, status: any) => {
  const response = await axiosService.patch(`comments/${comment_id}/${status}`);

  return response;
};

const likeUnlike = async (post_id: number, status: any) => {
  let body = {
    post: {
      user_id: 1,
      post_id: post_id,
      is_anonymous: false,
    },
  };
  const response = await axiosService.patch(
    `external/posts/${post_id}/${status}`,
    body
  );

  return response;
};

const likeUnlikeComment = async (comment_id: number, status: any) => {
  let body = {
    comment: {
      user_id: 1,
      comment_id: comment_id,
      is_anonymous: false,
    },
  };
  const response = await axiosService.patch(
    `v1/comments/${comment_id}/${status}`,
    body
  );

  return response;
};
const createUser = async (
  firstName: any,
  nickname: any,
  email: any,
  password: any,
  confirmPassword: any,
  is_facilitator: any
) => {
  let body = {
    user: {
      name: firstName,
      nickname: nickname,
      email: email,
      source: "admin",
      password: password,
      client_id: "CL002",
      is_facilitator: is_facilitator,
    },
  };
  console.log(body, "bodyyyyyy");
  const response = await axiosService.post(`users`, body);
  console.log(response);
  return response;
};

const updateUser = async (
  id: any,
  firstName: any,
  nickname: any,
  email: any,
  password: any,
  confirmPassword: any,
  is_facilitator: any
) => {
  let body = {
    user: {
      id: id,
      name: firstName,
      nickname: nickname,
      email: email,
      source: "admin",
      password: password,
      is_facilitator: is_facilitator,
    },
  };
  console.log(body, "bodyyyyyy");
  const response = await axiosService.put(`users/${id}`, body);
  console.log(response);
  return response;
};

const setGroups = async (name: any) => {
  let body = {
    group: {
      name: name,
      user_id: "1",
      parent_id: "1",
    },
  };
  const response = await axiosService.post(`groups`, body);

  return response;
};
const editGroup = async (name: any, id: number) => {
  let body = {
    group: {
      name: name,
      user_id: "1",
      parent_id: "1",
    },
  };

  const response = await axiosService.put(`groups/${id}`, body);

  return response;
};
const deleteGroup = async (id: number) => {
  const response = await axiosService.delete(`groups/${id}`);

  return response;
};
const deletePostPrivateUser = async (id: number) => {
  const response = await axiosService.delete(
    `external/posts/delete_post_private_user?id=${id}`
  );

  return response;
};
const deleteUser = async (id: number) => {
  const response = await axiosService.delete(`users/${id}`);

  return response;
};

const getCategory = async () => {
  // const user = await userAuth.getUser();

  const response = await axiosService.get(`categories`);
  const categories = response.data.categories.map(
    (item: { id: any; name: any }) => {
      return {
        id: item.id,
        value: item.id,
        name: item.name,
      };
    }
  );

  return categories;
};

const savePost = async (body: any) => {
  // const user = await userAuth.getUser();

  const response = await axiosService.post(`external/posts`, body);

  return response;
};

const saveGroupPost = async (body: any, member_id: string) => {
  // const user = await userAuth.getUser();

  const response = await axiosService.post(`external/posts`, body);

  return response;
};

const editPost = async (id: number, body: any) => {
  // const user = await userAuth.getUser();

  const response = await axiosService.put(`posts/${id}`, body);
  return response;
};

const getPosts = async () => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(`posts`);
  return response.data;
};
const getSinglePost = async (post_id: number) => {
  const response = await axiosService.get(`posts/${post_id}`);
  return response.data;
};

const getApprovedPosts = async (page: number) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `posts?q[status_eq]=approved&page=${page}`
  );
  return response.data;
};

const getTopPosts = async (page: number) => {
  // const user = await userAuth.getUser();
  console.log("hereeeeee");
  const response = await axiosService.get(
    `v1/posts/top_views?q[status_eq]=approved&q[is_private_eq]=false`
  );
  console.log(response.data.posts);
  return response.data;
};

const getTopOpinionPosts = async (page: number) => {
  // const user = await userAuth.getUser();
  console.log("hereeeeee");
  const response = await axiosService.get(
    `v1/posts/top_views?q[status_eq]=approved&q[post_type_eq]=1&q[is_private_eq]=false`
  );
  console.log(response.data.posts);
  return response.data;
};

const getTopEveryOnesPosts = async (page: number) => {
  // const user = await userAuth.getUser();
  console.log("hereeeeee");
  const response = await axiosService.get(
    `v1/posts/top_views?q[status_eq]=approved&q[post_type_eq]=0&q[is_private_eq]=false`
  );
  console.log(response.data.posts);
  return response.data;
};

// q[status_eq]=approved&q[category_id_eq]=1&
const getApprovedCommunityPosts = async (page: number) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `posts?q[status_eq]=approved&q[category_id_eq]=1&page=${page}`
  );
  return response.data;
};

const getApprovedCategoryPosts = async (
  categoryId: number,
  page: number,
  order: string
) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `posts?q[status_eq]=approved&q[post_type_eq]=0&q[category_id_eq]=${categoryId}&page=${page}&sorting_order=${order}`
  );
  return response.data;
};
const getApprovedPrivatePosts = async (page: number, order: string) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `posts?page=${page}&sorting_order=${order}&q[is_private_eq]=true`
  );
  return response.data;
};
// q[status_eq]=approved&q[category_id_eq]=2&
const getApprovedEventsPosts = async (page: number) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `posts?q[status_eq]=approved&q[category_id_eq]=2&page=${page}`
  );
  return response.data;
};
const getApprovedReportsPosts = async (page: number) => {
  // q[status_eq]=approved&q[category_id_eq]=3&
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `posts?q[status_eq]=approved&q[category_id_eq]=3&page=${page}`
  );
  return response.data;
};

// q[status_eq]=approved&q[category_id_eq]=2&

const getRejectedPosts = async (user_id: any) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `posts?q[status_eq]=rejected&q[user_id_eq]=${user_id}`
  );
  return response.data;
};
const getHistoryPosts = async (user_id: any, page: number) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `posts?q[user_id_eq]=${user_id}&page=${page}&q[is_private_eq]=false`
  );
  return response.data;
};
const getCommentHistoryPosts = async (user_id: any, page: number) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `posts?q[status_eq]=approved&q[comments_user_id_eq]=${user_id}&page=${page}`
  );
  return response.data;
};

const getOpinionPosts = async (page: any, order: any) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `posts?q[status_eq]=approved&q[post_type_eq]=1&page=${page}&sorting_order=${order}`
  );
  return response.data;
};

const checkNegativeWords = async (content: string) => {
  let body = {
    content: content,
  };
  // const user = await userAuth.getUser();

  const response = await axiosService.post(
    `negative_words/check_negative_words`,
    body
  );

  return response.data;
};

const createVote = async (voteOptionId: number, is_anonymous: boolean) => {
  let body = {
    vote: {
      user_id: "1",
      vote_option_id: voteOptionId,
      is_anonymous: is_anonymous,
    },
  };
  // const user = await userAuth.getUser();

  const response = await axiosService.post(`votes`, body);

  return response.data;
};

const replyComment = async (
  commentText: string,
  postId: number,
  parent_id: any,
  is_anonymous: string
) => {
  let body = {
    comment: {
      post_id: postId,
      content: commentText,
      parent_id,
      is_anonymous: is_anonymous,
    },
  };
  // const user = await userAuth.getUser();

  const response = await axiosService.post(`comments`, body);

  return response.data;
};

const createComment = async (
  commentText: string,
  postId: number,
  voteOptionId: any,
  is_anonymous: string,
  images: any
) => {
  try {
    let body = {
      comment: {
        post_id: postId,
        content: commentText,
        images_attributes: images,
        is_anonymous: is_anonymous,
      },
    };
    // const user = await userAuth.getUser();

    const response = await axiosService.post(`comments`, body);

    return response.data;
  } catch (error) {
    let body = {
      comment: {
        post_id: postId,
        content: commentText,
        is_anonymous: is_anonymous,
      },
    };
    // const user = await userAuth.getUser();

    const response = await axiosService.post(`comments`, body);

    return response.data;
  }
};

const editComment = async (
  commentId: number,
  commentText: string,
  postId: number,
  is_anonymous: string
) => {
  let body = {
    comment: {
      post_id: postId,
      content: commentText,
      user_id: "1",
      is_anonymous: is_anonymous,
    },
  };
  // const user = await userAuth.getUser();

  const response = await axiosService.put(`comments/${commentId}`, body);

  return response.data;
};

const saveSuggestion = async (body: any) => {
  const response = await axiosService.post(`v1/suggestions`, body);
  return response;
};
const getSuggestion = async (page: any) => {
  const response = await axiosService.get(`v1/suggestions?page=${page}`);
  return response.data;
};

const getAllUsers = async () => {
  const response = await axiosService.get(`external/users?per_page=2000000000`);
  return response.data;
};

const search = async (term: string, config: any) => {
  const response = await axiosService.get(
    `external/posts?q[status_eq]=approved&q[title_or_details_or_tags_tag_cont]=${term}`,
    config
  );
  return response.data.posts;
};

const addView = async (post_id: any) => {
  const response = await axiosService.patch(`v1/posts/${post_id}/add_views`);
  return response.data;
};

const createGroupEvent = async (body: any) => {
  const response = await axiosService.post(`external/posts`, body);
  return response.data;
};

const getGroupEvents = async (id: any) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `/external/posts?q[member_group_id_eq]=${id}`
  );
  return response.data;
};

const showGroupChat = async (group_id: number | string) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(
    `/external/chats?q[member_group_id_eq]=${group_id}`
  );
  return response.data;
};


const createChat = async (chatData: Createchat) => {
  return await axiosService.post("/external/chats", chatData);
};

const UpdateChat = async (chatData: Updatechat) => {
  return await axiosService.put("/external/chats", chatData);
};

const getChatMessages = async (chatId: number) => {
  return await axiosService.get(`/external/chats/${chatId}`);
};

const sendMessage = async (
  content: string,
  auth_token: string,
  chatId: string
) => {
  const url = `external/chats/${chatId}/messages`;
  const headers = {
    auth_token: auth_token,
    "Content-Type": "application/json",
  };

  try {
    const response = await axiosService.post(url, { content }, { headers });
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error);
  }
};

const addEventActivity = async (body: any) => {
  const response = await axiosService.post(`external/post_event_users`, body);
  return response.data;
};

const getEventApplicationDetails = async () => {
  const response = await axiosService.get(`/external/post_event_users`);
  return response.data.post_event_users;
};

const getEventPaymentDetails = async (eventId: number, user_id: number) => {
  const response = await axiosService.get(
    `/external/post_event_users?q[post_event_id_eq]=${eventId}&q[user_id_eq]=${user_id}&q[status_not_eq]=Cancelled`
  );
  return response.data.post_event_users;
};

const addUserToChat = async (
  chatId: number | string,
  userId: number | string,
  authToken: string,
  chatType: string = "group"
) => {
  try {
    // For group chat
    const url = `external/chats/${chatId}/add_user`;
    const response = await axiosService.post(
      url,
      { user_id: userId, can_message: true },
      {
        headers: {
          auth_token: authToken,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error managing chat:", error);
    throw error; // Propagate error to caller
  }
};

const addSingleUserToChat = async (
  member_group_id: number | string,
  userId: number | string,
  authToken: string
) => {
  const url = `external/chats`;
  const headers = {
    "Content-Type": "application/json",
  };

  const payload = {
    chat_type: "one_to_one",
    member_group_id: member_group_id,
    users: [
      {
        user_id: userId,
        can_message: true,
      },
    ],
  };

  try {
    const response = await axiosService.post(url, payload, { headers });
    console.log("Single user added to chat", response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding user to chat:", error);
  }
};

const updateChatType = async (chatId: number, chatType: string) => {
  const url = `/external/chats/${chatId}`;

  try {
    const response = await axiosService.put(url, { chat_type: chatType });
    return response.data;
  } catch (error) {
    console.error("Error updating chat type:", error);
  }
};

const deleteUserChat = async (
  chatId: number,
  userId: number,
  authToken: string
) => {
  const url = `/external/chats/${chatId}/remove_user/`;
  const headers = {
    auth_token: authToken,
    "Content-Type": "application/json",
  };
  try {
    const response = await axiosService.delete(url, {
      data: { user_id: userId },
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting user from chat:", error);
  }
};

const singleUserChatExist = async (
  member_group_id: number | string,
  userId: number | string,
  authToken: string
) => {
  const response = await axiosService.get(
    `/external/chats?q[chat_type_eq]=one_to_one&member_group_id=${member_group_id}&user_id=${userId}`,
    {
      headers: {
        auth_token: authToken,
        "Content-Type": "application/json",
      },
    }
  );
  console.log("Chat exist", response.data);

  return response.data;
};

const getLatestMessages = (
  chatId: number,
  lastMessageId: number,
  authToken: string
) => {
  return axiosService.get(`/external/messages`, {
    params: {
      q: {
        id_gt: lastMessageId,
        chat_id_eq: chatId,
      },
    },
    headers: {
      auth_token: authToken,
      "Content-Type": "application/json",
    },
  });
};

const appServices = {
  getEventPaymentDetails,
  getEventApplicationDetails,
  addEventActivity,
  getGroupEvents,
  createGroupEvent,
  editComment,
  replyComment,
  createComment,
  createVote,
  getGroups,
  getGroupsApp,
  getCategory,
  checkNegativeWords,
  savePost,
  getPosts,
  setGroups,
  editGroup,
  deleteGroup,
  editPost,
  createUser,
  getUsers,
  acceptRejectPost,
  getPendingPosts,
  getApprovedPosts,
  getRejectedPosts,
  getHistoryPosts,
  getApprovedCommunityPosts,
  getApprovedEventsPosts,
  getApprovedReportsPosts,
  getApprovedCategoryPosts,
  getSinglePost,
  likeUnlike,
  getOpinionPosts,
  saveSuggestion,
  getSuggestion,
  getTopPosts,
  addView,
  likeUnlikeComment,
  getCommentHistoryPosts,
  getPendingComments,
  acceptRejectComment,
  updateUser,
  deleteUser,
  getReportedPosts,
  getDashboard,
  getAllUsers,
  getApprovedPrivatePosts,
  getPostsInterval,
  search,
  getTopOpinionPosts,
  getTopEveryOnesPosts,
  getDashboard2,
  getDashboard3,
  getSuggestionsInterval,
  deletePostPrivateUser,
  saveGroupPost,
  createChat,
  sendMessage,
  getChatMessages,
  UpdateChat,
  showGroupChat,
  addUserToChat,
  deleteUserChat,
  getLatestMessages,
  addSingleUserToChat,
  singleUserChatExist,
  updateChatType,
};

export default appServices;
