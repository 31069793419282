import { Box, SxProps } from "@mui/material";

interface Props {
  color: string;
  sx?: SxProps;
}
function Separator({ color, sx }: Props) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "1px",
        backgroundColor: color,
        ...sx,
      }}
    />
  );
}

export default Separator;
