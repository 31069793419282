import React, { ReactElement } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Typography } from "@mui/material";
interface Props {
  onClick(): void;
  title: string;
}

function GCBackButton({ onClick, title }: Props): ReactElement {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ArrowBackIosNewIcon style={{ fontSize: 14, margin: 0, marginLeft: 10 }} />
      <Typography style={{ fontSize: 12 }} onClick={onClick}>
        {title}
      </Typography>
    </div>
  );
}

export default GCBackButton;
