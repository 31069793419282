import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import OptionsModal2 from "../OptionsModal2";
import AddUserSuccessModal from "./AddUserSuccessModal";

interface Option {
  title: string;
  path: string;
  disabled: boolean;
}

interface User {
  id: number | string;
  [key: string]: any; // Add more fields as needed
}

interface UserCardListProps {
  user?: User;
  userName: string;
  role?: string;
  currentUserId?: string | number;
  onApprove: () => void;
  onDelete?: () => void;
  onMoreOptions: () => void;
  onChatPress: (user: any) => void;
  onSwitchingRoles: (user: any) => void;
  showApproveIcon?: boolean;
  showDeleteIcon?: boolean;
  showMoreOptionsIcon?: boolean;
  userAvatar?: string;
  groupOwnerId?: number | string;
  isInChat?: boolean;
  onAddNewUser?: (user: any) => void;
  openAddUserModal?: boolean;
  setOpenAddUserModal?: (open: boolean) => void;
}

const UserCardList: React.FC<UserCardListProps> = ({
  user,
  userName,
  role,
  currentUserId,
  onChatPress,
  onSwitchingRoles,
  showApproveIcon = true,
  showDeleteIcon = true,
  showMoreOptionsIcon = true,
  isInChat,
  userAvatar,
  groupOwnerId,
  onAddNewUser,
  openAddUserModal,
  setOpenAddUserModal,
}) => {
  const [openOption, setOpenOptions] = useState(false);

  const options: Option[] = [
    { title: "ユーザーのページにアクセスする", path: "", disabled: false },
    { title: "チャット", path: "/chat", disabled: false },
    // Options that are available for the group owner
    { title: "役割を変える", path: "/switchingRole", disabled: false },
    { title: "グループチャットに追加する", path: "/addNewUser", disabled: false },
    { title: "強制撤退", path: "", disabled: false },
  ];

  const currentUserOptions: Option[] = [
    { title: "View", path: "", disabled: false },
    { title: "Option 3", path: "", disabled: false },
  ];

  const getOptions = (): Option[] => {
    // If the current user is the user themselves, return their specific options
    if (user && currentUserId === user.id) {
      return currentUserOptions;
    }

    // Modify the options to disable specific ones for non-owners
    return options.map((option) => ({
      ...option,
      disabled:
        currentUserId !== groupOwnerId &&
        (option.title === "役割を変える" ||
          option.title === "グループチャットに追加する" ||
          option.title === "強制撤退"),
    }));
  };

  // const getOptions = (): Option[] => {
  //   if (user && currentUserId === user.id) {
  //     return currentUserOptions;
  //   }
  //   let newOptions = [...options]
  //   if (currentUserId === groupOwnerId && !isInChat) {
  //     newOptions  = [...options, { title: "役割を変える", path: "/switchingRole" }, { title: "Add into group chat", path: "/addNewUser" }];
  //   }
  //   if(currentUserId === groupOwnerId){
  //     newOptions  = [...options, { title: "役割を変える", path: "/switchingRole" }];
  //   }
  //   return newOptions;
  // };

  const handleOptionsModal = () => {
    setOpenOptions(!openOption);
  };

  const handleSelect = (path: string) => {
    if (path === "/chat" && onChatPress) {
      onChatPress(user);
    }
    if (path === "/addNewUser" && onAddNewUser) {
      onAddNewUser(user);
    }

    if (path === "/switchingRole" && onSwitchingRoles) {
      onSwitchingRoles(user);
    }

    // if (path) {
    //   navigate(path);
    // }
  };
  
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        padding: "7px",
        margin: "0 16px",
        borderBottom: "2px solid #E5E5E5",
      }}
    >
      {/* Left Section: User Icon, Name, Role */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={userAvatar}
          sx={{ width: 30, height: 30, mr: 2 }}
          alt={userName?.charAt(0)?.toUpperCase()}
        />
        <Box>
          <Typography>{userName}</Typography>
          {role && (
            <Typography variant="body2" color="text.secondary">
              &nbsp; {role}
            </Typography>
          )}
        </Box>
      </Box>

      {/* Options Button */}
      {showMoreOptionsIcon && (
        <IconButton onClick={handleOptionsModal} sx={{ padding: 0 }}>
          <MoreHorizIcon sx={{ color: "#4792E0" }} />
        </IconButton>
      )}

      <OptionsModal2
        open={openOption}
        onClose={handleOptionsModal}
        options={getOptions()}
        onSelect={handleSelect}
        avatar={userAvatar}
        userName={userName}
      />

      <AddUserSuccessModal
        open={openAddUserModal || false}
        onClose={() => setOpenAddUserModal && setOpenAddUserModal(false)}
      />
    </Box>
  );
};

export default UserCardList;
