import axiosService from "../../axios-util/axios-services";
// import { Createchat } from "../../../util/types";

const createGroup = async (body: any) => {
  const response = await axiosService.post(`external/member_groups`, body);
  console.log(response);
  return response;
};

const editGroup = async (id: any, body: any) => {
  const response = await axiosService.put(`external/member_groups/${id}`, body);
  console.log(response);
  return response;
};

/**
 * Fetches all groups that a user is a member of
 * @returns {Promise<AxiosResponse<any>>} - the response from the server
 */
const getMembersGroups = async (ransank: string) => {
  // const user = await userAuth.getUser();
  const response = await axiosService.get(`external/member_groups${ransank}`);
  return response.data;
};

/**
 * Fetches posts that belong to a group
 * @param {string | number} group_id - the id of the group
 * @returns {Promise<AxiosResponse<any>>} - the response from the server
 */
const getGroupPosts = async (group_id: string | number) => {
  const response = await axiosService.get(`posts?q[member_group_id_eq]=${group_id}`);
  return response.data;
};

const search = async (term: string, config: any) => {
  const response = await axiosService.get(`external/member_groups?q[group_type_not_eq]=Private&q[name_i_cont]=${term}`, config);
  console.log(response);
  return response.data;
};

const searchById = async (id: string, config: any) => {
  const response = await axiosService.get(`external/member_groups?q[id_eq]=${id}`, config);
  console.log(response);
  return response.data;
};

const updateMember = async (id: any, status: any) => {
  const body = {
    status: status,
  };

  const response = await axiosService.put(`external/member_group_users/${id}`, body);
  return response.data;
};
const updateMemberRoleOnly = async (id: any, role: any) => {
  const body = {
    role: role,
  };

  const response = await axiosService.put(`external/member_group_users/${id}`, body);
  return response.data;
};

const deleteMember = async (id: any) => {
  const response = await axiosService.delete(`external/member_group_users/${id}`);
  return response.data;
};
const checkIsMembership = async (userId: any, groupId: any) => {
  const response = await axiosService.get(`external/member_group_users?q[member_group_id_eq]=${groupId}&q[user_id_eq]=${userId}`);
  return response.data;
};

const getMemberList = async (groupId: any) => {
  const response = await axiosService.get(`external/member_group_users?q[member_group_id_eq]=${groupId}}`);
  return response.data;
};

const getChatList = async (groupId: any) => {
  const response = await axiosService.get(`external/chats?filter_chats=1&q[member_group_id_eq]=${groupId}}`);
  return response.data;
};

const getAllChatList = async () => {
  const response = await axiosService.get(`external/chats`);
  return response.data;
};

// eslint-disable-next-line
const getApprovedMemberList = async (groupId: any) => {
  // q[status_eq]=approved
  const response = await axiosService.get(`external/member_group_users?q[member_group_id_eq]=${groupId}}`);
  return response.data;
};

const joinGroup = async (userId: any, groupId: any, status: any) => {
  const body = {
    role: "member",
    member_group_id: groupId,
    user_id: userId,
    status: status,
  };

  const response = await axiosService.post(`external/member_group_users`, body);
  return response.data;
};

const cancelEventApplication = async (body: any) => {
  const payload = {
    status: body?.status,
    application_details: body?.application_details,
  };
  const response = await axiosService.put(`external/post_event_users/${body?.id}`, payload);
  return response.data;
};

const userEvents = async (userId: any) => {
  const response = await axiosService.get(`external/post_event_users?per_page=200&q[user_id_eq]=${userId}?per_page=20000`);
  return response.data;
};

const editEvent = async (id: number, body: any) => {
  // const user = await userAuth.getUser();

  const response = await axiosService.put(`external/posts/${id}`, body);
  return response;
};

const getSingleEventById = async (id: number) => {
  const response = await axiosService.get(`external/posts/${id}`);
  return response.data;
};

const getCancelRequestsOfGroup = async (groupId: any) => {
  const response = await axiosService.get(`external/post_event_users?q[status_eq]=Cancel-pending&q[post_event_post_member_group_id_eq]=${groupId}`);
  return response.data;
};

const getSingleEvent = async (id: number) => {
  const response = await axiosService.get(`external/post_events/${id}`);
  return response.data;
};

const getEventPostApplications = async (id: number) => {
  const response = await axiosService.get(`external/post_event_users?per_page=200&q[post_event_post_member_group_id_eq]=${id}&q[status_in][]=applied&q[status_in][]=confirmed`);
  return response.data;
};

const updateUserStatus = async (body: any) => {
  const payload = {
    status: body?.status,
  };
  const response = await axiosService.put(`external/post_event_users/${body?.id}`, payload);
  return response.data;
};

const searchUsers = async (term: string) => {
  const response = await axiosService.get(`external/users?q[nickname_cont]=${term}`);
  console.log(response);
  return response.data;
};

const gcServices = {
  updateUserStatus,
  updateMemberRoleOnly,
  createGroup,
  getEventPostApplications,
  getCancelRequestsOfGroup,
  userEvents,
  getSingleEventById,
  getSingleEvent,
  editEvent,
  getMembersGroups,
  editGroup,
  search,
  getGroupPosts,
  deleteMember,
  updateMember,
  checkIsMembership,
  getMemberList,
  joinGroup,
  cancelEventApplication,
  getChatList,
  searchById,
  getAllChatList,
  searchUsers,
};
export default gcServices;
