import React from "react";
import { Modal, Box, Typography, Avatar } from "@mui/material";
import { colors } from "../../common/colors";
// import profile from "../../assets/images/profile_img.png";
import Separator from "../Separator";

interface OptionsModal2Props {
  open: boolean;
  onClose: () => void;
  options?: { title: string; path: string; disabled: boolean }[] | any[];
  onSelect: (options: string) => void;
  avatar?: string;
  userName?: string;
}

const OptionsModal2: React.FC<OptionsModal2Props> = ({ open, onClose, onSelect, options, avatar, userName }) => {
  const handleOptionSelect = (path: string, disabled: boolean) => {
    // If the option is disabled, pass an empty string to onSelect
    const selectedPath = disabled ? "" : path;

    onSelect(selectedPath); // Pass the selected path (or empty string) to the onSelect function
    onClose(); // Close the modal after selecting
  };

  



  // const handleOptionSelect = (path: string,disabled: boolean) => {

  //   onSelect(path);
  //   onClose();

  // };

  // const option = ["option1", "option2", "chat"];

  return (
    <Modal style={{ zIndex: 9999 }} open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={style}>
        <Avatar style={{ margin: 0 }} alt={userName?.charAt(0)?.toUpperCase()} src={avatar} />
        <Typography sx={{ marginTop: 2 }}>{userName}</Typography>
        <Separator color={colors.farm_gray100} sx={{ height: "2px" }} />
        {options?.map((option, index) => (
          <Box key={index} sx={{ width: "100%", textAlign: "center" }}>
            <Typography
              onClick={() => handleOptionSelect(option.path, option.disabled)}
              sx={{ marginTop: 2, marginBottom: 2, cursor: option.disabled ? "not-allowed" : "pointer", color: option.disabled ? "gray" : "black" }}
            >
              {option.title}
            </Typography>
            <Separator color={colors.farm_gray100} sx={{ height: "2px" }} />
          </Box>
        ))}
        <Typography onClick={() => onClose()} sx={{ marginTop: 2 }}>
          とじる
        </Typography>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "20px 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 2,
};

// const buttonStyle = {
//   color: colors.farm_blue200,
//   fontWeight: "600",
// };

export default OptionsModal2;
