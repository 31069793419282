import { Box,  Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { useNavigate } from "react-router-dom";
import TextButton from "../../components/GreenCity/TextButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Separator from "../../components/Separator";
import { useSelector } from "react-redux";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import VerticalListP2 from "../../components/lists/VerticalListP2";

const AddIcon = () => {
  return (
    <div style={styles.addIcon}>
      <AddRoundedIcon sx={{ width: 20, height: 20 }} />
    </div>
  );
};

function ViewGroupPost(): ReactElement {
  const navigate = useNavigate();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const [groupPost, setGroupPost] = useState<any>([]);
  // eslint-disable-next-line
  const [pagination, setPagination] = useState<any>({});

  const getGroupPost = async () => {
    let res = await gcServices.getGroupPosts(groupInfo?.id);
    console.log("group post:", res);
    setGroupPost(res.posts);
    setPagination(res.pagination);
  };

  useEffect(() => {
    getGroupPost();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={styles.container}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />

      <Box component="div" style={styles.topButtonContainer}>
        <div>
          <Typography style={styles.everyonePostStyle}>みんなの投稿</Typography>
        </div>
        <TextButton
          onClick={() => navigate("/createGroupPost")}
          Icon={AddIcon}
          title="投稿する"
        />
      </Box>
      <Separator sx={{ marginTop: "10px" }} color="gray" />
      <VerticalListP2
        page="group"
        items={groupPost}
        order={""}
        handleReport={function (post_id: number, status: any): void {
          throw new Error("Function not implemented.");
        }}
        onLike={function (post_id: number): void {
          throw new Error("Function not implemented.");
        }}
        onUnLike={function (post_id: number): void {
          throw new Error("Function not implemented.");
        }}
        likeLoading={false}
      />
    </div>
  );
}

export default ViewGroupPost;

const styles = {
  container: {
    paddingTop: 90,
  },
  addIcon: {
    border: "2px solid",
    borderRadius: 5,
    borderColor: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: "0 10px",
  },

  everyonePostStyle: {
    fontSize: 16,
  },
};
