import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { colors } from "../../common/colors";

import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { setGroupInfo } from "../../redux/Reducers/gcGroupReducer";
import { styles } from "../styles";

const defaultImage = require("../../assets/images/default.png");
function CreateGroupStepPII(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const [image, setImage] = useState<any>();
  const [pIIRequired, setRequired] = useState("NO");
  useEffect(() => {
    window.scrollTo(0, 0);
  
    // eslint-disable-next-line
  }, []);

  // Handle change for each field
  const handle3PIIChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequired((event.target as HTMLInputElement).value);
  };

  const handleNextStep = () => {
    dispatch(
      setGroupInfo({
        piiRequired: pIIRequired === "YES",
      })
    );

    navigate("/creategroupsteptwo");
  };

  useEffect(() => {
    setRequired(groupInfo?.piiRequired ? "YES" : "NO");
    if (groupInfo.groupImage) {
      console.log(groupInfo.groupImage);
      const icon =
        groupInfo?.groupImage instanceof File
          ? URL.createObjectURL(groupInfo.groupImage)
          : `${groupInfo?.groupImage}` || defaultImage;
      console.log("icon", icon);
      setImage(icon); // Set the image URL
    }
  }, [groupInfo]);
  return (
    <div style={{ paddingTop: 100, height: "100vh" }}>
      <Box>
        <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 16,
            borderBottomColor: colors.farm_gray15,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <img
              alt=""
              style={{
                height: 32,
                width: 32,
                borderRadius: 18,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: colors.farm_green500,
              }}
              src={image || defaultImage}
            />
            <Typography style={{ fontSize: 14, fontWeight: 600 }}>
              &nbsp;{groupInfo.groupName}
            </Typography>
          </div>
        </Box>
        <Box style={{ padding: 20 }}>
          <Typography style={{ fontSize: 12, }}>
          グループに参加するメンバーの氏名をメンバーリストに表示するかを設定できます。
なお、リーダー・サプリーダー・会計メンバーの氏名は以下の設定に関係なく、グループメンバーに表示されます。
メンバーの氏名は、リーダーとサブリーダーのみが問覧できます。
          </Typography>
          <br></br>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            value={pIIRequired}
            onChange={handle3PIIChange}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="NO"
              sx={{ paddingBottom: 4 }}
              control={<Radio />}
              label="メンバーのニックネームのみ表示する。
                    "
            />
            <FormControlLabel
              value="YES"
              control={<Radio />}
              label="メンバーのニックネームと氏名を表示する。
                    "
            />
          </RadioGroup>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              handleNextStep();
            }}
            style={{
              ...styles.introSliderBtn,
              marginTop: 40,
              width: 220,
            }}
            size={"large"}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            <span style={{ paddingRight: 30, paddingLeft: 40 }}>つぎへ</span>
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default CreateGroupStepPII;
