import * as React from "react";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import profile from "../assets/images/profile_img.png";
import { LanguageList } from "react-translator-component";
import LogoutIcon from "@mui/icons-material/Logout";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { useState } from "react";
import appServices from "../services/app-services/appServices";
import LoadingModal from "../components/LoadingModal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import { T } from "react-translator-component";
import { Box, IconButton, colors } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { reset } from "../redux/Reducers/userAuthReducer";
import setAuthorizationToken from "../services/axios-util/set-authorization-token";
import EventCalendar from "../components/GreenCity/EventCalender";
import { colors as farm_colors } from "../common/colors";
import ButtonArrow from "../components/GreenCity/ButtonArrow";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import IconButtonArrow from "../components/GreenCity/IconButtonArrow";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import IconWithBackground from "../components/GreenCity/IconWithBackground";
import ChatIcon from "@mui/icons-material/Chat";
import { clearGroupInfo, getMembersGroups, setGroupInfo } from "../redux/Reducers/gcGroupReducer";
import gcServices from "../services/app-services/greenCity/gcServicesR6";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { User } from "../util/types";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
interface listChats {
  id: number; // or string, depending on your data structure
  chat_type: string;
  creator_id: number;
  member_group_id: number;
  member_group_name?: string;
  member_group_image?: string;
  users: User[];
}
export default function MyFarm() {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: any) => state.userAuth);
  const { member_groups } = useSelector((state: any) => state.gcGroup);
  const [mygroups, setMyGroups] = useState<any>([]);
  const [allGroups, setAllGroups] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [dates, setDates] = useState([]);
  const [allChats, setAllChats] = useState<listChats[]>([]);

  const navigate = useNavigate();

  // const getAllChat = async () => {
  //   try {
  //     const chats = await gcServices.getAllChatList(); // Call the modified getChatList function
  //     setAllChats(chats.chats); // Set the retrieved chats into the state
  //   } catch (error) {
  //     console.error("Error fetching chats:", error); // Handle any errors
  //   }
  // };

  const getUserEvents = async (user_id: number) => {
    try {
      const res = await gcServices.userEvents(user_id);

      const eventDates = res?.post_event_users
        .filter((event: any) => event?.status !== "Cancelled") // Exclude events with status "Cancelled"
        .map((event: any) => ({
          title: event?.event_title,
          color: event?.status === "applied" ? "#86BB57" : "gray",
          start: event?.event_start_date,
          end: event?.event_end_date,
          id: event?.id,
          post_id: event?.post_id,
        }));

      setDates(eventDates);
    } catch (error) {
      console.error(error);
    }
  };

  const getSelectedEvent = async (date: any) => {
    try {
      const res = await gcServices.getSingleEventById(date.post_id);

      navigate("/calenderevent", { state: { event: [res?.post] } });
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (user?.id) {
      apiCalls();
    }
    // const ransank = `?per_page=10&q[member_group_users_status_in]=approved,unpaid_approved&q[member_group_users_user_id_eq]=${user.id}`;

    // const ransank = `?per_page=5
    // &q[member_group_users_user_id_eq]=${user.id}
    // &q[or][]=user_id_eq:${user.id}
    // &q[member_group_users_status_in][]=approved
    // &q[member_group_users_status_in][]=unpaid_approved`;

    const rs = `?per_page=5&&q[member_group_users_status_in][]=approved&q[member_group_users_status_in][]=unpaid_approved&q[member_group_users_user_id_eq]=${user.id}&q[owner_id_eq]=${user.id}`;
    dispatch(getMembersGroups(rs));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    const combinedGroups = [
      ...(member_groups?.member_groups || []),
      ...(mygroups || []), // Add another array if necessary
    ];

    // Sort by created_at in descending order (newest first)
    const sortedGroups = combinedGroups.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

    setAllGroups(sortedGroups);
  }, [mygroups, member_groups]);

  const [expanded, setExpanded] = useState("0");
  const [accordianHeight, setAccordianHeight] = useState(40);

  const handleChange = (expand: string) => {
    setExpanded(expand);
    if (expand === "0") {
      setAccordianHeight(40);
    } else {
      setAccordianHeight(100);
    }
  };

  const apiCalls = async () => {
    setVisible(true);
    try {
      getUserEvents(user.id);
      await appServices.getHistoryPosts(user.id, 1);
      const mygroup = `?per_page=5&q[user_id_eq]=${user.id}`;
      const mygroups = await gcServices.getMembersGroups(mygroup);
      // getAllChat();
      setMyGroups(mygroups.member_groups);
      // setCommunityPosts(posts.posts);
      // setCommunityPostsPagination(posts.pagination);
      setVisible(false);
    } catch (error: any) {
      if (error?.response?.status === 403) {
        logout();
      }
      setVisible(false);
    }
  };

  const handleLangage = () => {
    navigate(0);
  };

  const handleGroup = (group: any) => {
    if (group?.owner?.id === user?.id) {
      navigate("/groupleader", {
        state: {
          groupInfo: { ...group, user: group?.owner, role: group?.role },
        },
      });
    } else if (group?.owner?.id !== user?.id) {
      if (group?.member_group_users[0].status === "unpaid_approved") {
        navigate("/grouppayment", { state: { groupInfo: group } });
      } else if (group?.member_group_users[0].status === "approved") {
        navigate("/groupleader", {
          state: {
            groupInfo: { ...group, user: group?.owner, role: group?.role },
          },
        });
      } else {
        handleReview(group);
      }
    }
  };

  const handleReview = (groupInfo: any) => {
    // Dispatch action to save group details
    dispatch(
      setGroupInfo({
        user: groupInfo?.owner,
        id: groupInfo.id,
        groupName: groupInfo.name,
        groupIsPublic: groupInfo?.group_type,
        groupImage: groupInfo?.group_icon ? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}` : "",
        groupMainImage: groupInfo?.banner_image ? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.banner_image}` : "", // From state
        groupDesc: groupInfo?.description, // From state
        groupAnnouncements: {}, // From state
        groupWebURLs: groupInfo?.social_links, // You can replace this with the actual value
        groupApprovalRequired: true,
        piiRequired: groupInfo?.personal_information_needed ? "YES" : "NO",
        groupIsLimited: true,
        groupMaxMembers: groupInfo.participants_count,
        groupMemberShipFeeAmount: groupInfo?.fee_amount,
        groupPaymentAutomatic: groupInfo?.payment_type,
        groupAutomaticPaymentDay: groupInfo?.membership_fee_deduction_date,
        isMonthly: groupInfo?.fee_type === "yearly", // Static value
        groupPaymentField: groupInfo?.fee_amount,
        groupPaymentDescription: groupInfo?.fee_explanation,
        memberGroupUsers: groupInfo?.member_group_users,
      })
    );
    navigate("/groupinforeview");
  };

  const logout = () => {
    dispatch(reset());
    setAuthorizationToken("");
    navigate("/navigator");
  };

  return (
    <React.Fragment>
      <div style={{ ...styles.farmContainer, padding: 0 }}>
        <LoadingModal visible={visible} handleClose={() => console.log("here")} />
        <LanguageList onChange={handleLangage} Theme="Dropdown" />
        <div style={{ ...styles.farmProfileInfo, flexDirection: "column" }}>
          <img src={user.image !== null ? "data:image/jpeg;base64," + user.image : profile} alt={"userimg"} style={styles.farmProfileIcon} />

          <span style={styles.farmInfoTitle}>{user.nickname}</span>
          {user?.id_kind === 2 && (
            <span
              style={{
                fontSize: 10,
                color: colors.red[400],
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              temp user
            </span>
          )}
          {user?.nav === "1" && (
            <IconButton
              onClick={() => {
                logout();
              }}
              color="inherit"
            >
              Logout &nbsp;
              <LogoutIcon />
            </IconButton>
          )}
        </div>

        <div>
          <EventCalendar getSelectedEvent={getSelectedEvent} dates={dates} />
        </div>

        <div style={styles.portionBg}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <BorderColorOutlinedIcon /> 意見広場・みんなの投稿
          </p>
          {/* // */}

          <ButtonArrow title="プライベート投稿" onClick={() => navigate("/posthistory")} />

          <ButtonArrow title={T("Comment_History")} onClick={() => navigate("/commenthistory")} />

          <ButtonArrow title="「みんなの投稿」履歴" onClick={() => navigate("/everyoneshistory")} />
        </div>

        <div style={styles.portionBg}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <ChatIcon /> チャット
          </p>

          <ButtonArrow title="チャットリスト" onClick={() => navigate("/userchatlist")} />
          {/* 
          <Box display="flex" flexDirection="column">
            {allChats.map((chat) => {
              let icon;

              if (chat.chat_type === "one_to_one") {
                icon = (
                  <img
                    style={{ width: "26px", height: "26px", borderRadius: "50%", objectFit: "cover" }}
                    src={chat.users[1]?.image ? `data:image/png;base64,${chat.users[1].image}` : profile_placeholder}
                    alt={chat.users[0]?.nickname || "User"}
                  />
                );
              } else if (chat.chat_type === "one_to_many") {
                icon = (
                  <div style={{ display: "flex" }}>
                    <img
                      alt="User"
                      style={{
                        width: "26px",
                        height: "26px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src={chat.users[1]?.image ? `data:image/png;base64,${chat.users[1].image}` : profile_placeholder}
                    />
                    <img
                      alt="User"
                      style={{
                        width: "26px",
                        height: "26px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        marginLeft: "-20px",
                      }}
                      src={chat.users[2]?.image ? `data:image/png;base64,${chat.users[2].image}` : profile_placeholder}
                    />
                  </div>
                );
              } else {
                icon = (
                  <img
                    style={{ width: "26px" }}
                    src={chat.member_group_image ? `data:image/png;base64,${chat.member_group_image}` : group_placeholder}
                    alt={chat.member_group_name || "Group"}
                  />
                );
              }

              return (
                <IconButtonArrow
                  key={chat.id} // Use a unique key in a real app
                  icon={icon} // Pass the icon variable
                  title={chat.chat_type === "group" ? chat.member_group_name || "No Group Name" : getChatUserName(chat.users, user.id)}
                  role={""}
                  onClick={() => {
                    navigate("/userchat", {
                      state: {
                        chatId: chat.id,
                        chatUsers: chat.users,
                        chat_type: chat.chat_type,
                        isGroupChat: chat.chat_type === "group",
                        isCreator: chat.creator_id === user.id,
                        groupMembers: [],
                        groupInfo: { id: chat.member_group_id, name: chat.member_group_name },
                      },
                    });
                  }}
                />
              );
            })}
          </Box> */}
        </div>

        <div style={styles.portionBg}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <PeopleAltOutlinedIcon /> グループ
          </p>
          <Box
            style={{
              display: "flex", // Flexbox to align items in one row
              flexDirection: "row", // Horizontal row
              gap: 6, // Optional: gap between items
            }}
          >
            <IconButtonArrow
              icon={<IconWithBackground backgroundColor={farm_colors.farm_green500} icon={<SentimentSatisfiedAltIcon style={{ color: "white", fontSize: 20 }} />} />}
              title="新しく作る"
              onClick={() => {
                dispatch(clearGroupInfo());
                navigate("/disclosure");
              }}
            />
            <IconButtonArrow
              icon={<IconWithBackground backgroundColor={farm_colors.farm_green500} icon={<SearchIcon style={{ color: "white", fontSize: 20 }} />} />}
              title="探す"
              onClick={() => {
                navigate("/group_search");
              }}
            />
          </Box>
          <IconButtonArrow
            // icon={
            //   <IconWithBackground
            //     backgroundColor={farm_colors.farm_blue200}
            //     icon={<AddLinkIcon style={{ color: "white", fontSize: 20 }} />}
            //   />
            // }
            icon={<IconWithBackground backgroundColor={farm_colors.farm_blue200} icon={<AddLinkIcon style={{ color: "white", fontSize: 20 }} />} />}
            title="招待リンクからグループに参加する"
            onClick={() => {
              navigate("/groupinvitation");
            }}
          />

          {/* groups list */}
          <Box display="flex" flexDirection="column">
            {allGroups?.map((group: any, index: number) => (
              <IconButtonArrow
                key={index} // Use a unique key in a real app
                icon={<img alt="" height={26} width={26} style={{ borderRadius: 13 }} src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${group?.group_icon}`} />}
                title={group?.name || ""} // Title from the group
                role={group?.owner?.id === user?.id ? "leader" : group?.role} // Title from the group
                onClick={() => {
                  handleGroup(group);
                }}
              />
            ))}
          </Box>
        </div>

        <div style={styles.portionBg}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <span style={{ fontSize: 18, border: 1, borderRadius: 18, borderStyle: "solid", paddingLeft: 5, paddingRight: 5, width: 10, height: 10 }}>&#165;</span> お支払い関係
          </p>
          {/* // */}

          <ButtonArrow title="グループに支払う" onClick={() => {}} />

          <ButtonArrow title={"立て替え精算"} onClick={() => {}} />

          <ButtonArrow title="利用履歴" onClick={() => {}} />
          <ButtonArrow title="立て替え精算履歴" onClick={() => {}} />
        </div>

        <div style={styles.portionBg}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            <SettingsOutlinedIcon /> その他
          </p>
          {/* // */}

          <Accordion
            // variant="elevation"
            className=""
            style={{ boxShadow: "none", marginLeft: 16, marginRight: 16, borderRadius: 8, marginBottom: 14, height: accordianHeight, paddingTop: -4 }}
            expanded={expanded === "3" ? true : false}
            onChange={() => handleChange(expanded !== "0" ? "0" : "3")}
          >
            <AccordionSummary
              sx={{ color: "black", minHeight: 40, height: 40 }}
              expandIcon={
                expanded === "3" ? (
                  <KeyboardArrowUpOutlinedIcon fontSize={"large"} style={{ color: "black", fontSize: 22 }} />
                ) : (
                  <ArrowForwardIosIcon style={{ color: "black", fontSize: 18 }} />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14 }}>{T("Questionnaire")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  onClick={(event) => {
                    const target = event.target as HTMLElement;
                    if (target.tagName === "A") {
                      event.preventDefault();
                      const url = (target as HTMLAnchorElement).href;
                      // You can add additional logic here, such as opening the URL in a new tab/window
                      //window.open(url, "_system");
                      try {
                        const messageData = {
                          url: url,
                          condition: true,
                        };
                        window.parent.postMessage({ type: "url", data: messageData }, "*");
                      } catch (error) {}
                    }
                  }}
                >
                  <a style={{ fontSize: 14 }} href="https://forms.office.com/r/7fnxwKXWRW">
                    https://forms.office.com/r/7fnxwKXWRW
                  </a>
                </p>
                <Divider style={styles.farmDivider3} />
              </div>
            </AccordionDetails>
          </Accordion>

          <ButtonArrow title={"ダッシュボード"} onClick={() => navigate("/dashboard")} />

          <ButtonArrow title="災害時の安否確認" onClick={() => {}} />
        </div>
      </div>
    </React.Fragment>
  );
}
