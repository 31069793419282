import { Backdrop, CircularProgress } from "@mui/material";
interface Props {
  visible: boolean;
  handleClose?: () => void;
}
export default function LoadingModal({ visible, handleClose }: Props) {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={visible} onClick={handleClose}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
