import { Box } from "@mui/material";
import { ReactElement } from "react";

interface IconWithBackgroundProps {
  icon: ReactElement;
  backgroundColor?: string;
}

export default function IconWithBackground({
  icon,
  backgroundColor = "green", // Default color is green
}: IconWithBackgroundProps) {
  return (
    <Box
      style={{
        backgroundColor,
        borderRadius: "50%", // Round shape
        padding: 4, // Adjust padding for size
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {icon}
    </Box>
  );
}
