import { Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { colors } from "../../common/colors";
interface Props {
  onClick(): void;
  title: string;
}
export default function ButtonArrow({ title, onClick }: Props) {
  return (
    <Box
      style={{
        height: 40,
        marginLeft: 16,
        marginRight: 16,
        borderRadius: 8,
        marginBottom: 12,
        padding: 10,
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: colors.farm_gray100,
        borderStyle: "solid",
      }}
      onClick={onClick}
    >
      <Typography style={{ fontSize: 12 }} color={"black"}>
        {title}
      </Typography>
      <ArrowForwardIosIcon style={{ marginRight: 6, fontSize: 14 }} />
    </Box>
  );
}
