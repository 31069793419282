import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import TableComponent from "../GreenCity/TableComponent";
import dayjs from "dayjs";
import LoadingModal from "../LoadingModal";
import GCBackButton from "../GreenCity/GCBackButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import gcServices from "../../services/app-services/greenCity/gcServicesR6";

const LeaderCancellationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { detail } = location.state || {};
  console.log(detail);

  const cancel = async () => {
    try {
      setLoading(true);
      //   body = {
      //     id: userPaymentDetail?.id,
      //     status: "Cancelled",
      //   };

      //   await gcServices.cancelEventApplication(body);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <LoadingModal visible={loading} handleClose={() => {}} />

      <div
        style={{
          paddingTop: 100,
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: "12px",
        }}
      >
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box component="div" style={styles.topButtonContainer}>
          <Box sx={{ display: "flex" }}>
            <Typography style={{ marginTop: "5px" }}>
              Event Cancellation
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: 4 }}>
          <TableComponent
            data={[
              {
                col1: "Venue Name",
                col2: detail?.venue_name,
              },
              {
                col1: "Postal Code",
                col2: detail?.postal_code,
              },
              {
                col1: "Address",
                col2: detail?.address,
              },
              {
                col1: "Dates",
                col2: (
                  <Box>
                    <Typography>
                      {detail?.application_start_date}{" "}
                      {dayjs(detail?.application_start_date).format("dddd")}
                    </Typography>
                    <Typography>
                      {detail?.application_end_date}{" "}
                      {dayjs(detail?.application_end_date).format("dddd")}
                    </Typography>
                  </Box>
                ),
              },
            ]}
          />
        </Box>
        <Button
          color="secondary"
          sx={styles.submitButton}
          onClick={cancel}
          size={"large"}
          variant="outlined"
          endIcon={
            <ArrowForwardIosIcon
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                margin: "10px",
              }}
            />
          }
        >
          Cancel Event
        </Button>
      </div>
    </Box>
  );
};

const styles = {
  topButtonContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginTop: 10,
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
};

export default LeaderCancellationPage;
