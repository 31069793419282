import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { colors } from "../../common/colors";
import { T } from "react-translator-component";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";

function Disclosure(): ReactElement {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgree(event.target.checked);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  
    // eslint-disable-next-line
  }, []);
  return (
    <div
      style={{
        backgroundColor: colors.farm_gray15,
        paddingLeft: "8%",
        paddingTop: 110,
      }}
    >
      <GCBackButton title="もどる" onClick={() => navigate(-1)} />
      <Box
        style={{
          height: 400,
          width: "90%",
          borderColor: colors.farm_gray100,
          borderWidth: 2,
          borderRadius: 8,
          borderStyle: "solid",
          backgroundColor: "#FFFFFF",
          overflow: "scroll",
          padding: 10,
        }}
      >
        <Typography
          style={{ fontWeight: 600, textAlign: "center", fontSize: 18 }}
        >
          本書には、Pay
        </Typography>
        <Typography style={{ padding: 2 }}>
          本書には、現在の予想、見積もり、予測、仮定に基づく将来予測に関する記述が含まれています。これらの記述は、将来のパフォーマンスを保証するものではなく、予測が難しいリスク、不確実性、仮定を含んでいます。実際の結果は、これらの将来予測に基づくものとは大きく異なる可能性があります。
          提供される情報は一般的な情報提供のみを目的としており、法的、財務的、または専門的なアドバイスと解釈されるべきものではありません。本書で表明された意見は予告なく変更される場合があります。
          本書には、現在の予想、見積もり、予測、仮定に基づく将来予測に関する記述が含まれています。これらの記述は、将来のパフォーマンスを保証するものではなく、予測が難しいリスク、不確実性、仮定を含んでいます。実際の結果は、これらの将来予測に基づくものとは大きく異なる可能性があります。
          提供される情報は一般的な情報提供のみを目的としており、法的、財務的、または専門的なアドバイスと解釈されるべきものではありません。本書で表明された意見は予告なく変更される場合があります。
        </Typography>
      </Box>
      <Stack spacing={2} style={{ width: "90%" }}>
        <span style={{ height: 10 }}></span>

        <FormGroup>
          <Typography>{T("I_am_responsible")}</Typography>

          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: colors.farm_blue200, // Set the unchecked box color
                  "&.Mui-checked": {
                    color: colors.farm_blue200, // This sets the box color when checked
                  },
                  "&.Mui-checked .MuiSvgIcon-root": {
                    color: colors.farm_blue200, // This sets the checkmark color to white when checked
                    // background: colors.farm_blue200,
                  },
                }}
                onChange={handleAgreeChange}
                value={agree}
              />
            }
            label={T("I_have_reviewed_the")}
          />
        </FormGroup>
        <Button
          onClick={() => {
            navigate("/creategroupstep1");
          }}
          disabled={!agree}
          style={{
            alignSelf: "center",
            borderRadius: 20,
            backgroundColor: colors.farm_blue200,
          }}
          size={"large"}
          variant="contained"
          endIcon={<FontAwesomeIcon icon={faChevronRight} />}
        >
          <span style={{ minWidth: 200 }}>つぎへ</span>
        </Button>
      </Stack>
      <div style={{ height: 120 }}></div>
    </div>
  );
}

export default Disclosure;
