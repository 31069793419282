import React from "react";
import {
  Modal,
  Box,
  Typography,
  
} from "@mui/material";
import { colors } from "../../../common/colors";

import GCBackButton from "../../GreenCity/GCBackButton";
import ButtonPrimary from "../../ButtonPrimary";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PIIField from "../../GreenCity/PIIField";
import { useSelector } from "react-redux";

// Define the props interface
interface PIIViewInfoProps {
  groupName:string;
  groupImage:string;
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const PIIViewInfo: React.FC<PIIViewInfoProps> = ({
  groupName,
  groupImage,
  open,
  onClose,
  onContinue,
}) => {

  const { user } = useSelector((state: any) => state.userAuth);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          backgroundColor: colors.farm_gray15,
          paddingLeft: "8%",
          paddingTop: 60,
          height: "100%",
        }}
      >
        <GCBackButton title={'グループトップ'} onClick={onClose}  />
        <Box
          style={{
            height: 400,
            width: "90%",
         
            overflow: "scroll",
            padding: 10,
            paddingBottom:100
          }}
        >
          <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img
            alt=""
            style={{
              height: 32,
              width: 32,
              borderRadius: 18,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: colors.farm_green500,
            }}
            src={groupImage}
          />
          <Typography style={{ fontSize: 14, fontWeight: 600 }}>
            &nbsp;{groupName}
          </Typography>
        </div>
        <br></br>
        <Typography
        style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                fontSize: 14,
                marginBottom: 6,
              }}
            >
            <InfoOutlinedIcon/>  &nbsp;個人情報の提供
            </Typography>

        <PIIField  fieldTitle="ニックネーム：" fieldData={user?.nickname} />
        <PIIField  fieldTitle="氏名：" fieldData={user?.name} />
        {/* <PIIField  fieldTitle="生年月日：" fieldData={user?.dob} />
        <PIIField  fieldTitle="ニックネーム" fieldData={user?.address} /> */}


        </Box>
       
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom:100,
            paddingLeft:-'8%'
          }}
        >
        
            <ButtonPrimary title="つぎへ"   onClick={onContinue} />
          
         
       
        </Box>
        
      </Box>
    </Modal>
  );
};

export default PIIViewInfo;
