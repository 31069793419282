import {
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { colors } from "../../common/colors";
import ButtonOutlinedCentered from "../../components/ButtonOutlined";
import { styles } from "../styles";
import { useAppDispatch } from "../../redux/store";
import { setGroupInfo } from "../../redux/Reducers/gcGroupReducer";
import { useSelector } from "react-redux";

const defaultImage = require("../../assets/images/placeholder.png");
function CreateGroupStep1(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [groupName, setGroupName] = useState("");
  const [image, setImage] = useState<any>();

  const [imageFile, setImageFile] = useState<any>();
  const groupInfo = useSelector((state: any) => state.gcGroup);

  const [isPrivate, setIsPrivate] = useState("PUBLIC");
  
  useEffect(() => {
    window.scrollTo(0, 0);
  
    // eslint-disable-next-line
  }, []);


  const handleIsPrivateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //public private
    setIsPrivate((event.target as HTMLInputElement).value);
  };

  const fileInputRef = useRef<any>();

  const handleButtonClick = () => {
    fileInputRef?.current.click();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const imageUrl = URL.createObjectURL(file); // Generate URL from the file
      setImage(imageUrl); // Set the image URL
    }
  };
  const handleNextStep = () => {
    if (image && groupName.trim().length > 3) {
      dispatch(
        setGroupInfo({
          id: groupInfo?.id || "",
          edit: groupInfo?.id ? true : false,
          groupName,
          groupIsPublic: isPrivate === "PUBLIC",
          groupImage: imageFile || image,
        })
      );

      navigate("/steppii");
      // navigate("/creategroupsteptwo");
    } else {
      console.log(groupName, image);
    }
  };

  useEffect(() => {
    if (groupInfo) {
      setGroupName(groupInfo?.groupName);
      let isPublic;
      if (typeof groupInfo?.groupIsPublic === "string") {
        isPublic = groupInfo?.groupIsPublic.toUpperCase(); // Convert string to uppercase
        console.log("here", groupInfo?.groupIsPublic);
      } else if (typeof groupInfo?.groupIsPublic === "boolean") {
        isPublic = groupInfo?.groupIsPublic === true ? "PUBLIC" : "PRIVATE"; // Convert boolean to YES/NO
        console.log("here", groupInfo?.groupIsPublic);
      } else {
        console.log("here", groupInfo?.groupIsPublic);
      }

      setIsPrivate(isPublic);
    
  

      if (groupInfo.groupImage) {
        const icon =
          groupInfo?.groupImage instanceof File
            ? URL.createObjectURL(groupInfo.groupImage)
            : `${groupInfo?.groupImage}` || defaultImage;

        setImage(icon); // Set the image URL
      }
    }
  }, [groupInfo]);

  return (
    <div style={{ paddingTop: 100, height: "100vh" }}>
      <Box>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <PeopleAltOutlinedIcon style={{ fontSize: 20 }} />
          <Typography style={{ fontSize: 14 }}>
            &nbsp; グループを作る
          </Typography>
        </Box>
        <Box
          style={{
            backgroundColor: colors.farm_gray15,
            padding: 16,
          }}
        >
          <Typography
            style={{
              borderBottomColor: colors.farm_green150,
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
              fontSize: 14,
            }}
          >
            グループ名（必須）
          </Typography>
          <TextField
            fullWidth
            hiddenLabel
            id="outlined-basic"
            variant="outlined"
            value={groupName}
            onChange={(e: any) => setGroupName(e?.target?.value)}
            InputProps={{
              style: {
                height: 40, // Set your desired height here
                padding: "4px 8px", // Adjust padding to fit your design
              },
            }}
            style={{ marginTop: 6 }} // Keep margin top if needed
          />

          <Box style={{ marginTop: 40 }}>
            <Typography
              style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                fontSize: 14,
              }}
            >
              グループアイコン（必須）
            </Typography>

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 10,
              }}
            >
              <img
                alt=""
                style={{
                  height: 100,
                  width: 100,
                  borderRadius: 50,
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: colors.farm_green500,
                }}
                src={image || defaultImage}
              />
            </Box>
            <div>
              <ButtonOutlinedCentered
                title={"画像を選ぶ"}
                onClick={handleButtonClick}
                pLR={6}
              />
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the file input
              />
            </div>

            <Typography
              style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
              }}
            >
              &nbsp;グループ公開設定（必須）。
            </Typography>
            <RadioGroup
              aria-labelledby="payment-radio-buttons-group-label"
              value={isPrivate}
              onChange={handleIsPrivateChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="PUBLIC"
                control={<Radio sx={{ alignSelf: "flex-start" }} />} // Aligns the radio button to the top
                label={
                  <div>
                    <Typography variant="body1">公開にする</Typography>

                    <Typography variant="body2" color="textSecondary">
                      めぶくコミュニティの全員が閲覧可能なグループです。
                    </Typography>
                    <br />
                  </div>
                }
              />
              <FormControlLabel
                value="PRIVATE"
                control={<Radio sx={{ alignSelf: "flex-start" }} />} // Aligns the radio button to the top
                label={
                  <div>
                    <Typography variant="body1">非公開にする</Typography>

                    <Typography variant="body2" color="textSecondary">
                      招待されたユーザーのみ間可能なグループです
                    </Typography>
                    <br />
                  </div>
                }
              />
            </RadioGroup>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  handleNextStep();
                }}
                style={{
                  ...styles.introSliderBtn,
                  marginTop: 40,
                  width: 220,
                }}
                size={"large"}
                variant="contained"
                endIcon={<ArrowForwardIosIcon />}
              >
                <span style={{ paddingRight: 30, paddingLeft: 40 }}>
                  つぎへ
                </span>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default CreateGroupStep1;
