import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { colors } from "../../../common/colors";
import { styles } from "../../../pages/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Define the props interface
interface DisclosureModalProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const DisclosureModal: React.FC<DisclosureModalProps> = ({
  open,
  onClose,
  onContinue,
}) => {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgreed(event.target.checked);
  };

  const handleContinue = () => {
    if (isAgreed) {
      onContinue();
      onClose();
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  
    // eslint-disable-next-line
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          backgroundColor: colors.farm_gray15,
          paddingLeft: "8%",
          paddingTop: 60,
          height: "100%",
        }}
      >
        <Box
          style={{
            height: 400,
            width: "90%",
            borderColor: colors.farm_gray100,
            borderWidth: 2,
            borderRadius: 8,
            borderStyle: "solid",
            backgroundColor: "#FFFFFF",
            overflow: "scroll",
            padding: 10,
          }}
        >
          <Typography
            style={{ fontWeight: 600, textAlign: "center", fontSize: 18 }}
          >
            本書には、Pay
          </Typography>
          <Typography style={{ padding: 2 }}>
            本書には、現在の予想、見積もり、予測、仮定に基づく将来予測に関する記述が含まれています。これらの記述は、将来のパフォーマンスを保証するものではなく、予測が難しいリスク、不確実性、仮定を含んでいます。実際の結果は、これらの将来予測に基づくものとは大きく異なる可能性があります。
            提供される情報は一般的な情報提供のみを目的としており、法的、財務的、または専門的なアドバイスと解釈されるべきものではありません。本書で表明された意見は予告なく変更される場合があります。
            本書には、現在の予想、見積もり、予測、仮定に基づく将来予測に関する記述が含まれています。これらの記述は、将来のパフォーマンスを保証するものではなく、予測が難しいリスク、不確実性、仮定を含んでいます。実際の結果は、これらの将来予測に基づくものとは大きく異なる可能性があります。
            提供される情報は一般的な情報提供のみを目的としており、法的、財務的、または専門的なアドバイスと解釈されるべきものではありません。本書で表明された意見は予告なく変更される場合があります。
          </Typography>
        </Box>
        <FormControlLabel
          control={<Checkbox checked={isAgreed} onChange={handleAgreeChange} />}
          label="I agree to the disclosure terms"
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleContinue}
            disabled={!isAgreed}
            style={{
              ...styles.introSliderBtn,
              marginTop: 40,
              width: 220,
            }}
            size={"large"}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            <span style={{ paddingRight: 30, paddingLeft: 40 }}>Continue</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DisclosureModal;
