import React from "react";

import profile from "../../assets/images/profile_img.png";
import { colors } from "../../common/colors";
interface Props {
  user?: any;
  data: any;
}

const TableComponent = ({ user, data }: Props) => {
 
  return (
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
      <tbody>
        {user && (
          <tr key={0}>
            <td
              style={{
                backgroundColor: colors.farm_gray10,
                border: `1px solid ${colors.farm_gray15}`,
                width: "40%",
              }}
            >
              リーダー
            </td>
            <td style={{ border: `1px solid ${colors.farm_gray15}` }}>
              <img
                src={
                  user?.image !== null
                    ? "data:image/jpeg;base64," + user?.image
                    : profile
                }
                alt={"user_img"}
                style={{
                  height: 30,
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderRadius: 15,
                  margin: 1,
                }}
              />
               {user?.nickname} &nbsp; {user?.name}
            </td>
          </tr>
        )}
        {data.map((row: any, index: number) => (
          <tr key={index}>
            {/* Apply background color to every cell in the first column */}
            <td
              style={{
                backgroundColor: colors.farm_gray50,
                border: `1px solid ${colors.farm_gray15}`,
                width: "40%",
              }}
            >
              {row.col1}
            </td>
            <td style={{ border: `1px solid ${colors.farm_gray15}` }}>
              {row.col2}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableComponent;
