import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { Box, Typography } from "@mui/material";
import appServices from "../../services/app-services/appServices";
import { useSelector } from "react-redux";
import EventCalendar from "../../components/GreenCity/EventCalender";
import LoadingModal from "../../components/LoadingModal";
import EventDetailsList from "../../components/lists/EventDetailsList";
import TextButton from "../../components/GreenCity/TextButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { styles as oldstyles } from "../styles";

const AddIcon = () => {
  return (
    <div style={styles.addIcon}>
      <AddRoundedIcon sx={{ width: 20, height: 20 }} />
    </div>
  );
};
const GroupEventsCalendar = () => {
  const navigate = useNavigate();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const { user } = useSelector((state: any) => state.userAuth);

  const [posts, setPosts] = useState([]);
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEvents = async () => {
    try {
      setLoading(true);

      const response = await appServices.getGroupEvents(groupInfo.id);
      console.log(response);
      
      const datesArray = response.posts.flatMap(
        (post: { post_events: any[] }) =>
          post.post_events.map((event) => ({
            title: event.title,
            start: event.start_date,
            end: event.end_date,
          }))
      );

      setPosts(response.posts);
      setDates(datesArray);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getSelectedEvent = (date: any) => {
    const filteredPosts = posts.filter((post: any) =>
      post.post_events.some((event: any) => {
        return event.start_date === date.start && event.end_date === date.end;
      })
    );

    navigate("/calenderevent", { state: { event: filteredPosts } });
  };

  useEffect(() => {
    getEvents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <div
        style={{
          paddingTop: 100,
          paddingLeft: 16,
          paddingBottom: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <LoadingModal visible={loading} handleClose={() => {}} />

        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box component="div" style={styles.topButtonContainer}>
          <Box style={styles.eventPostingBox}>
            <Typography style={{ fontWeight: "700" }}>
              <img
                src={`${groupInfo?.groupImage}`}
                alt={"user"}
                style={{
                  ...oldstyles.farmProfileIcon,
                  height: 50,
                  width: 50,
                  margin: 0,
                }}
              />
              &nbsp;{groupInfo?.groupName}
            </Typography>
          </Box>
          {groupInfo?.owner?.id === user?.id && (
            <TextButton
              onClick={() =>
                navigate("/createnewevent", {
                  state: {
                    groupInfo: groupInfo,
                  },
                })
              }
              Icon={AddIcon}
              title="投稿する"
            />
          )}
        </Box>
        {/* <Box style={styles.eventPostingBox}>
          <Box component="div" style={styles.eventPostingIcon}>
            中
          </Box>
          <Typography style={{ fontWeight: "700", marginTop: "5px" }}>
            イベントカレンダー
          </Typography>
        </Box>
        <TextButton
          onClick={() =>
            navigate("/createnewevent", {
              state: {
                groupInfo: groupInfo,
              },
            })
          }
          Icon={AddIcon}
          title="投稿する"
        /> */}
      </div>
      <Box>
        <EventCalendar getSelectedEvent={getSelectedEvent} dates={dates} />
      </Box>
      <Box>
        {posts?.map((event: any) => (
          <Box key={event.id}>
            <EventDetailsList
              items={[event]}
              page={""}
              order={""}
              handleReport={function (post_id: number, status: any): void {
                throw new Error("Function not implemented.");
              }}
              onLike={function (post_id: number): void {
                throw new Error("Function not implemented.");
              }}
              onUnLike={function (post_id: number): void {
                throw new Error("Function not implemented.");
              }}
              likeLoading={false}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const styles = {
  eventPostingIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px 3px 6px",
    color: "white",
  },
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItmes: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  addIcon: {
    border: "2px solid",
    borderRadius: 5,
    borderColor: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: "0 10px",
  },
};

export default GroupEventsCalendar;
