import React, { useEffect, useState } from "react";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { styles } from "../styles";
import GroupIcon from "@mui/icons-material/Group";
import ButtonArrow from "../../components/GreenCity/ButtonArrow";
import ButtonArrow2 from "../../components/GreenCity/ButtonArrow2";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import LoadingModal from "../../components/LoadingModal";
// import { useSelector } from "react-redux";

const MemberList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { groupInfo } = location.state || {};
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEventApplications = async () => {
    try {
      setLoading(true);

      const res = await gcServices.getEventPostApplications(groupInfo?.id);
      const filteredData = res.post_event_users.map(
        (event: {
          event_title: string;
          event_start_date: string;
          event_id: number;
        }) => ({
          eventTitle: event.event_title,
          eventStartDate: event.event_start_date,
          eventId: event.event_id,
        })
      );
      setEvents(filteredData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getEventApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box
        style={{
          paddingTop: 100,
          paddingLeft: 16,
          paddingBottom: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <LoadingModal visible={loading} handleClose={() => {}} />

        <GCBackButton title="もどる" onClick={() => navigate(-1)} />

        <Box style={style.eventPostingBox}>
          <Typography style={{ fontWeight: "700" }}>
            <img
              src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`}
              alt={"img"}
              style={{
                ...styles.farmProfileIcon,
                height: 50,
                width: 50,
                margin: 0,
              }}
            />
            &nbsp;{groupInfo?.name}
          </Typography>
        </Box>
      </Box>
      <div style={styles.portionMemberListBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <GroupIcon /> メンバー一覧
        </p>

        <ButtonArrow
          title={`グループメンバー全員　${
            groupInfo?.member_group_users?.length || 0
          }人`}
          onClick={() => {
            navigate("/chatlist", {
              state: {
                groupInfo: groupInfo,
              },
            });
          }}
        />

        <ButtonArrow title="グループ参加承認待ち　26人" onClick={() => {}} />
      </div>

      <div style={styles.portionMemberListBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          参加者確定のイベントメンバー
        </p>
        {events.map((event: any, index: number) => (
          <ButtonArrow2
            key={index}
            title={event.eventTitle}
            desc={event.eventStartDate}
            onClick={() => {
              navigate("/eventusers", { state: { event: event } });
            }}
          />
        ))}
      </div>

      <div style={styles.portionMemberListBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>終了したイベント</p>

        <ButtonArrow
          title="2024/1/1餅つき大会"
          onClick={() => {
            navigate("");
          }}
        />

        <ButtonArrow title="ハロウィンイベント" onClick={() => {}} />
        <Typography sx={style.showMore}>もっと見る</Typography>
      </div>
    </Box>
  );
};
const style = {
  eventPostingIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px 3px 6px",
    color: "white",
  },
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItmes: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  showMore: {
    color: "#A2A2A2",
    textAlign: "right",
    marginRight: "15px",
  },
};

export default MemberList;
